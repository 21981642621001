@mixin btn() {
	@include default-animation(color, background-color, border-color);
	display: inline-block;
	margin-bottom: 0;
	padding: 0.5em 1.28571428571em; // padding: 9px 18px;
	font-size: 1.4rem;
	font-weight: $medium;
	line-height: 1.42857142857; // line-height: 20px;
	text-align: center;
	vertical-align: middle;
	white-space: nowrap;
	cursor: pointer;
	border: 1px solid transparent;
	border-radius: $border-radius-base;
	background-image: none;

	&__ico {
		margin-right: 0.42857142857em; // margin-right: 6px;
		margin-left: -0.42857142857em; // margin-right: -6px;

		&--r {
			margin-right: -0.42857142857em; // margin-right: -6px;
			margin-left: 0.42857142857em; // margin-right: 6px;
		}

		&--light {
			--icon-fill: $white;
		}
	}

	& + .btn {
		margin-left: 0.85714285714em; // margin-left: 12px;
	}

	/**
	 *  Styles for mouse focus ONLY
	 *  Not keyborad focus!
	 */
	&:focus:not(.focus-visible),
	&:active:not(.focus-visible),
	&:active:focus:not(.focus-visible) {
		border-color: transparent;
		outline: none;
		box-shadow: none;
	}
}

@mixin btn-size($size:"") {

	@if $size == "" {
		@include icon-size('medium');

		&--md {
				padding: 0.67857142857em 1.71428571429em; // padding: 9.5px 24px;
				font-size: 1.4rem;

			&.btn--ico {
				padding-left: 0;
				padding-right: 0;
				width: 3em;
			}


			& .btn__ico {
				margin-right: 0.57142857142em;
				margin-left: -0.57142857142em;


				&--r {
					margin-right: -0.57142857142em;
					margin-left: 0.57142857142em;
				}
			}
		}

		&--lg {
			padding: 0.52777777777em 1.33333333333em; // padding: 9.5px 24px;
			font-size: 1.8rem;

			&.btn--ico {
				padding-left: 0;
				padding-right: 0;
				width: 2.66666666667em; // width: 48px;
			}


			& .btn__ico {
				margin-right: 0.55555555555em;
				margin-left: -0.55555555555em;
				&--r {
					margin-right: -0.55555555555em;
					margin-left: 0.55555555555em;
				}
			}
		}
	} @else if $size == "md"{
		@include icon-size('medium');

		&--md {
				padding: 0.67857142857em 1.71428571429em; // padding: 9.5px 24px;
				font-size: 1.4rem;

			&.btn--ico {
				padding-left: 0;
				padding-right: 0;
				width: 3em;
			}


			& .btn__ico {
				margin-right: 0.57142857142em;
				margin-left: -0.57142857142em;


				&--r {
					margin-right: -0.57142857142em;
					margin-left: 0.57142857142em;
				}
			}
		}
	} @else if $size == "lg" {
		@include icon-size('large');

		&--lg {
			padding: 0.52777777777em 1.33333333333em; // padding: 9.5px 24px;
			font-size: 1.8rem;

			&.btn--ico {
				padding-left: 0;
				padding-right: 0;
				width: 2.66666666667em; // width: 48px;
			}


			& .btn__ico {
				margin-right: 0.55555555555em;
				margin-left: -0.55555555555em;
				&--r {
					margin-right: -0.55555555555em;
					margin-left: 0.55555555555em;
				}
			}
		}
	}
}

@mixin btn-ico() {
	&--ico {
		padding-left: 0;
		padding-right: 0;
		width: 36px;

		& .btn__ico {
			margin: 0;
		}

		&.btn_transparent {
			background: none;
		}

		&.btn_no-width {
			width: auto;
		}

		&.btn--lg,
		&.btn--md {
			& .btn__ico {
				margin: 0;
			}
		}
	}

	@each $size, $value in map-get($hdn-breakpoint, "to") {
		@media (max-width: $value) {

			&--ico-#{$size} {
				padding-right: 0;
				padding-left: 0;
				width: 36px;

				& .btn__ico {
					margin: 0;
				}

				& .btn__txt {
					display: none;
				}

				&.btn--md {
					width: 42px;
				}

				&.btn--lg {
					width: 48px;
				}
			}
		}
	}
}

@mixin btn-block() {
	&--block {
		display: block;
		width: 100%;

		& + .btn--block {
				margin-top: 12px;
				margin-left: 0;
		}

	}
}



// Btn-tiles - list items used as buttons
@mixin btn-tiles($item: "item") {

	// Styles for list items
	&__#{$item} {
	position: relative;
	z-index: 1;
	display: flex;
	margin: -0.1em 0;
	padding: 1.2em 0;
	align-items: center;

		&:focus {
			margin: -0.1em -1.2em;
			padding: 1.2em;
			border-radius: $border-radius-base;
			background-color: $white;
		}

		&:hover {
			margin: -0.1em -1.2em;
			padding: 1.2em;
			cursor: pointer;
			border-color: transparent;
			border-radius: $border-radius-base;
			background-color: $gray-lighter;
		}

		// Remove brand border-color
		&.def-focus:focus:not(.focus-visible),
		&.def-focus:active:not(.focus-visible),
		&.def-focus:active:focus:not(.focus-visible) {
			border-color: $gray-lighter;
		}

	}

	&__ico {
		margin-right: 0.75em;
		font-size: 2.4em;
		color: $gray-base;
	}

	&__bd {
		min-width: 0;
	}

	&__tl {
		font-size: 1.4em;
		font-weight: $bold;
		color: $gray-darker;
		@include ellipsis();
	}

}

@mixin btn-icon($modifier: sm) {
	border: none;
	background: transparent;
	box-shadow: none;
	padding: 0;
	color: $gray-dark;

	@if $modifier == sm {
		width: 2em;
		height: 2em;
		font-size: 1.6rem;
		border-radius: $border-radius-base;
		position: relative;

		&:before {
			content: '';
			display: block;
			opacity: 0;
			position: absolute;
			transition-duration: .15s;
			transition-timing-function: cubic-bezier(0.4,0.0,0.2,1);
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background: $gray-lighter;
			transform: scale(0);
			transition-property: transform,opacity;
			border-radius: $border-radius-base;
		}

		&:hover {

			&:before {
				opacity: 1;
				transform: scale(1);
			}
		}
	}
}

@mixin btn-clear() {
	padding: 0;
	border: none;
	outline: none;
	background: transparent;
}

@mixin btn-style($object){
	$object-color-default: map-get(map-get($object, 'normal'), color);
	$object-bg-default: map-get(map-get($object, 'normal'), bg);
	$object-bdr-color-default: map-get(map-get($object, 'normal'), border-color);
	$object-box-shadow-default: map-get(map-get($object, 'normal'), box-shadow);

	color: $object-color-default;
	background: $object-bg-default;
	border-color: $object-bdr-color-default;
	box-shadow: $object-box-shadow-default;


	//##HOVER STATE
	@if map-has-key($object, 'hover') == true {
		&:hover {
			color: map-get(map-get($object, 'hover'), color);
			background: map-get(map-get($object, 'hover'), bg);
			border-color: map-get(map-get($object, 'hover'), border-color);
			box-shadow: map-get(map-get($object, 'hover'), box-shadow);
			text-decoration: none;
		}
	} @else {
			&:hover {
				color: $object-color-default;
				background-color: color-mix(in srgb, $object-bg-default, black 10%);
				border-color: color-mix(in srgb, $object-bdr-color-default, black 10%);
				text-decoration: none;
			}
	}



	//## FOCUS STATE
	@if map-has-key($object, 'focus') == true {
		&:focus,
		&.focus {
			color: map-get(map-get($object, 'focus'), color);
			background: map-get(map-get($object, 'focus'), bg);
			border-color: map-get(map-get($object, 'focus'), border-color);
			box-shadow: map-get(map-get($object, 'focus'), box-shadow);
		}
	} @else {
			&:focus,
			&.focus {
				color: $object-color-default;
				background-color: color-mix( in srgb, $object-bg-default, black 10%);
				border-color: color-mix( in srgb, $object-bdr-color-default, black 10%);
			}
	}

	//## ACTIVE STATE
	@if map-has-key($object, 'active') == true {
		&:active {
			color: map-get(map-get($object, 'active'), color);
			background: map-get(map-get($object, 'active'), bg);
			border-color: map-get(map-get($object, 'active'), border-color);
			box-shadow: map-get(map-get($object, 'active'), box-shadow);

			&:hover ,
			&:focus ,
			&.focus {
				color: map-get(map-get($object, 'active'), color);
				background: map-get(map-get($object, 'active'), bg);
				border-color: map-get(map-get($object, 'active'), border-color);
				box-shadow: map-get(map-get($object, 'active'), box-shadow);
			}

		}
	} @else {
			&:active {
				color: $object-color-default;
				background-color: color-mix( in srgb, $object-bg-default, black 10%);
				border-color: color-mix( in srgb, $object-bdr-color-default, black 10%);

				&:hover ,
				&:focus ,
				&.focus {
					color: $object-color-default;
					background-color: color-mix( in srgb, $object-bg-default, black 17%);
					border-color: color-mix( in srgb, $object-bdr-color-default, black 17%);
				}

			}
	}

	&:active {
		background-image: none;
	}
	&.disabled ,
	&[disabled] ,
	fieldset[disabled] & {
		&:hover ,
		&:focus ,
		&.focus {
			background-color: $object-bg-default;
			border-color: $object-bdr-color-default;
			color: $object-color-default;
		}
	}

	.badge {
		background-color: $object-bg-default;
		border-color: $object-bdr-color-default;
	}
}
