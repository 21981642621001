.normal {
	font-weight: $regular;
}

.light-text {
	font-weight: $light;
}

.medium-text {
	font-weight: $medium;
}

/**
 *  Ellipsis break text on the edge of  container and put three dots
 *
 *  1. The default vertical-align is to have the bottom edge of the inline-block box lined up
 *		with the baseline of the surrounding text. Even if there is no surrounding text,
 *		the layout engine still has to make room for an entire line of text.
 */
.ellipsis {
	@include ellipsis;
}

// Same effect as the one above but with line-clamp title is going to be in 2 rows
.ellipsis-1 {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.ellipsis-2 {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.word-break-word {
	overflow-wrap: break-word;
	word-wrap: break-word; //Added this for IE11
	word-break: break-word;
}

.break-word {
	word-wrap: break-word;
}

.text-black {
	color: $black;
}

.text-white {
	color: $white;
}

.text-darker {
	color: $gray-darker;
}

.text-dark {
	color: $gray-dark;
}

.text-gray {
	color: $gray-dark;
}

.text-success {
	color: $semantic-positive-dark;
}

.text-danger {
	color: $semantic-alert-dark;
}

.text-muted {
	color: $text-muted;
}

.d-block {
	display: block;
}

.h-100 {
	height: 100%;
}

.w-100 {
	width: 100%;
}

.f-none {
	float: none;
}

.f-left {
	float: left;
}

.f-right {
	float: right;
}

p {

	&.lg {
		font-size: $font-size-p-lg; // font-size: 16px;
		line-height: 1.5; // line-height: 24px;
	}

	&.sm {
		font-size: 1.3rem; // font-size: 13px;
		line-height: 1.53846154; // line-height: 20px;
	}

	&.xs {
		font-size: 1.2rem; // font-size: 12px;
	}
}

.link {
	display: inline;
	font-family: $font-family-base;
	font-size: 1.4rem;
	line-height: 1.42857;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}

	&-back {
		font-weight: $medium;
		text-transform: uppercase;
		color: $gray-darker;

		&:hover,
		&:focus {
			font-weight: $medium;
			text-decoration: none;
			color: $gray-darker;
		}

		&-ico {
			@include angie-spacing(margin, right, spacing-04);
			font-weight: $medium;
			color: $gray-darker;
		}
	}
}

a.lg,
.link.lg {
	font-size: 1.6rem;
	line-height: 1.5em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

/**
 *  Each function which prints values for tags h1-h6 and class .fs-h1-.fs-h6
 *  valuses can be edited in file variables/_var-typography.scss
 */
@include headings();

.text-right {
	text-align: right;
}
