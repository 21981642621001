
//## CKEDITOR

/**  Class ck-editor-contet is class what we use for output on all app
 *  Courses
 *  LP
 *  Library.....
 */
 /* stylelint-disable */


.ck-editor-content {
	@include clearfix;
	line-height: 1.42857;

	& h1,
	& h2,
	& h3,
	& h4,
	& h5,
	& h6 {
		font-weight: $regular;
		line-height: 1.42857;
	}

	& h1 {
		margin-top: 20px;
		margin-bottom: 10px;
		font-size: 3.2rem; // ~32px
	}

	& h2 {
		margin-top: 20px;
		margin-bottom: 10px;
		font-size: 2.5rem; // ~25px
	}

	& h3 {
		margin-top: 20px;
		margin-bottom: 10px;
		font-size: 2rem; // ~20px
	}

	& h4 {
		margin: 10px 0;
		font-size: 1.8rem; // ~18px
	}

	& h5 {
		margin: 10px 0;
		font-size: 1.6rem; // ~16px
	}

	& h6 {
		margin: 10px 0;
		font-size: 1.4rem; // ~14px
	}

	& p {
		margin: 10px 0;
		font-size: 1.4rem; // ~14px
	}

	& ul,
	& ol {
		font-size: 1.4rem; // ~14px
		list-style-position: outside;
	}

	& li,
	& ol {
		font-size: 1.4rem; // ~14px
	}

	& li + li {
		margin-top: 12px;
	}

	& blockquote {
		margin: 1em 40px;
		padding: 1px 8px 1px 20px;
		font-family: $font-family-base;
		font-size: 1.6rem;
		font-style: italic;
		border-color: $gray;

		& p {
			margin: 1em 0;
		}
	}

	& img {
		max-width: 100%;
		height: auto;
	}
}

//## CKEDITOR

/**  Class ck-editor-post is class what we use for input and output on Forums
 *  Create post (ck-editor input)
 *  Show single post (output)
 */

.ck-editor-post {
	font-family: $font-family-base;
	font-size: 10px;
	word-wrap: break-word;
	color: $gray-darker;

	h2 + p,
	h2 + ul,
	h2 + ol,
	h2 + blockquote,
	h2 + img {
		margin-top: 0.6em;
	}

	p {

		& + p {
			margin-top: 1.2em;
		}

		& .video-responsive {
			margin-top: 0;
		}

		& img + br {
			display: none;
		}
	}

	/* p + ul ,
	p + ol ,
	p + blockquote ,
	ul + p ,
	ul + ul ,
	ul + ol ,
	ul + blockquote ,
	ol + p ,
	ol + ul ,
	ol + ol ,
	ol + blockquote ,
	blockquote + p ,
	blockquote + ul ,
	blockquote + ol ,
	blockquote + blockquote , {
		margin-top: 1.2em;
	} */

	h2 {
		margin-top: 2.666666em; // 64px / 24px = 3em
		font-size: 2.4em;
		line-height: 1.5;
	}

	p,
	ul,
	ol {
		margin-top: 1.2em;
		margin-bottom: 0;
		font-size: 2em;
		line-height: 1.8;
	}

	ul,
	ol {
		padding-left: 2.4em;
		list-style-position: outside;
	}

	blockquote {
		margin-top: 1.2em;
		margin-bottom: 0;
		padding: 0  0  0 2.4em; // 48px / 20px = 2.4em
		font-size: 2em;
		font-style: italic;
		line-height: 1.8;
		color: $gray;

		& p {
			font-size: 1em;
		}
	}

	img {
		display: block;
		margin: 0 auto;
		max-width: 100%;

		& + img {
			margin-top: 1.2em; // 24px / 20px (paragraph) = 1.2em
		}
	}

	.video-responsive {
		margin: 2.4em auto 0 auto;
	}

	img + p {
		margin-top: 1.2em;
	}
}

//## CKEDITOR COMMENTS

/** Class ck-editor-post-comments is class what we use for input and output on Post comments
 *  Create post comment (ck-editor input)
 *  Show single post comment (output)
 */

.ck-editor-post-comments {
	font-family: $font-family-base;
	font-size: 10px;
	color: $gray-darker;

	p {
		font-size: 1.4em;  // 14px / 10px = 1.4em
		line-height: 1.71428;
	}

	p + p {
		margin-top: 1.28571em;  // 18px / 14px = 1.28571em
	}
}

//

/**  Classes below (body.cke_ltr.......cke_panel_listItem h2) are for
 *  style dropdown on CK EDITOR  for (paragraph format) when we have custom
 *  CK EDITOR like in case .ck-editor-post
 */
body.cke_ltr {
	font-family: $font-family-base;
	font-size: 12px;
	color: $gray-darker;
}

.cke_panel_grouptitle {
	font-size: 11px;
}

.cke_panel_listItem p {
	font-size: 18px;
}

.cke_panel_listItem h2 {
	font-size: 27px;
}

//@@ MOBILES BREAK POINT MAX-WIDTH:480px

@media (max-width: 480px) {

	.ck-editor-content {

		& p {
			font-size: 1.4rem;
		}

		& li,
		& ol {
			font-size: 1.4rem;
		}

		& blockquote {
			font-size: 1.4rem;
		}
	}
}

//@@ LU GRID EXTRA SMALL MOBILE BREAK POINT MAX-WIDTH:480px

@media (max-width: 479px) {

	.ck-editor-post {
		font-size: 8.5px;
	}
}
