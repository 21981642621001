.alert {
	margin-bottom: 1.8em; // margin-bottom: 18px;
	padding: 1.2em 1.8em; // padding: 12px 18px;
	font-size: 1rem;
	color: $gray-darker;
	border: 1px solid;
	border-radius: $border-radius-base;

	&__hdg {
		@include headings(h5);
	}

	&__txt {
		font-size: 1.4em;
	}

	&__ico {
		@include angie-spacing(margin, right, spacing-08);
	}

	& a {
		font-size: 1.4em;
		font-weight: $bold;
		text-decoration: underline;
		color: $gray-darker;

		&:hover {
			text-decoration: none;
			color: $gray-darker;
		}
	}
}

.alert-info {
	border-color: $semantic-progress-dark;
	background-color: $semantic-progress-pale;

	& .alert__ico {
		color: $semantic-progress-dark;
	}

	&--solid {
		background-color: $semantic-progress-pale-solid;
	}
}

.alert-success {
	border-color: $semantic-positive-dark;
	background-color: $semantic-positive-pale;

	& .alert__ico {
		color: $semantic-positive-dark;
	}
}

.alert-warning {
	border-color: $semantic-warning-dark;
	background-color: $semantic-warning-pale;

	& .alert__ico {
		color: $semantic-warning-dark;
	}
}

.alert-danger {
	border-color: $semantic-alert-dark;
	background-color: $semantic-alert-pale;

	& .alert__ico {
		color: $semantic-alert-dark;
	}
}
