.grid-wrapper {
	@include angie-spacing(padding, y, spacing-12);
	border: 1px solid $gray-light;
	border-radius: $border-radius-extra-large;
	background-color: $white;

	&__grid-control {
		@media screen and (min-width: $lu-screen-m-min) {
			@include angie-spacing(padding, x, spacing-16);
			@include angie-spacing(padding, top, spacing-12);
		}
	}

	&__no-content {
		box-shadow: unset;
	}
}

.bulk-info {
	@include spacing(padding, x, spacing); // 12px
	@include spacing(padding, y, spacing-tight); // 16px
	text-align: center;
	border: 1px solid $gray-base;
	border-radius: $border-radius-base;
}
