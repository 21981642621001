/**
 *  Mixin used to print top padding for the drawer
 *	to cover all layout header variations
 */
@mixin angie-drawer-headers() {
	@each $header, $value in $angie-drawer-headers {
		&--#{$header} {
			padding-top: $value;
		}
	}
}

/**
 * Mixin used to print top-padding and transition
 * for handling scroll up and down behaviour of the layout headers
 */

/** Arguments for mixin
	- $direction up, down
*/
@mixin angie-drawer-headers-scroll($direction) {

	&.drawer {
		@if $direction == 'down' {
			@each $key, $value in $angie-drawer-scroll-header-obj {
				&--#{$key} {
					@include print-list($value);
					transition: $drawer-scroll-transition;
				}
			}
		}
		@if $direction == 'up' {
			transition: $drawer-scroll-transition;
		}
	}
}
