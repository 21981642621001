//## AVATAR

/**
 *  Avatar initials we use trough all app, by default size of circle is 36px and it has different
 *  modifiers for different sizes, usualy has pale gray background color with dark gray text
 *
 *  1. Default font size for avatar is 14 pixels
 *  2. Width and height are based on font size (14px) and they are 36px by default
 *  3. Margin-right is based on font size (14px) and its 12px
 */
.avatar {
	display: flex;
	margin-right: 0.8571428em; // [3]
	width: 2.57142857em; // [2]
	height: 2.57142857em; // [2]
	align-items: center;
	justify-content: center;
	font-size: 1.4rem; // [1]
	font-weight: $bold;
	color: $gray-dark;
	border-radius: 50%;
	background: $gray-light;

	/**
	 *  Modifier for  small (sm) size
	 *
	 *  1. Font size for medium size of avatar is 36 pixels
	 *  2. Width and height is 64px
	 */
	&--sm {
		width: 2.5em;
		height: 2.5em;
		font-size: 2.6rem;
	}

	/**
	 *  Modifier for  medium (md) size
	 *
	 *  1. Font size for medium size of avatar is 36 pixels
	 *  2. Width and height are based on font size (36px) and they are 90px
	 */
	&--md {
		width: 2.5em; //[2]
		height: 2.5em; //[2]
		font-size: 3.6rem; // [1]
	}

	//Modifier for  user in header
	&--usr {
		margin-right: 0;
	}

	&--lg {
		width: 2.85714286em; // 120px
		height: 2.85714286em; // 120px
		font-size: 4.2rem;
		font-weight: $bold;
		color: $gray-darker;
	}

	&--chat {
		width: 40px;
		height: 40px;
		border: 1px solid $white;
	}

	&--ring {
		position: relative;
		margin: 0 auto;
		width: 140px;
		height: 140px;
		font-size: 64px;

		&::before {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 95%;
			height: 95%;
			content: '';
			border: 2px solid $white;
			border-radius: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&--border {
		border: 1px solid $white;
	}
}

//## GRAVATAR

/**  Places where we use:
 *  Dashboard > Leaderboard Widget
 *  Messages > Message A
 *  Header > User settings

 *  1. Default font size for gravatar is 10 pixels
 *  2. Width and height are based on font size (10px) and they are 36px by default
 *  3. Margin-right is based on font size (10px) and its 12px
 */

.gravatar {
	@include default-animation(box-shadow);
	display: flex;
	margin-right: 1.2em; // [3]
	width: 3.6em; // [2]
	height: 3.6em; // [2]
	overflow: hidden;
	font-size: 1rem; // [1]
	border-radius: 50%;

	& > img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}

	&--no-margin {
		margin: 0;
	}

	&--lg {
		width: 9.23076923em; // 120px
		height: 9.23076923em; // 120px
		font-size: 1.3rem;
		font-weight: $bold;
		color: $white;
	}

	&--md {
		width: 9em;
		height: 9em;
	}

	&--border {
		border: 1px solid $white;
	}

	&--ring {
		position: relative;
		margin: 0 auto;
		width: 140px;
		height: 140px;

		&::before {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 95%;
			height: 95%;
			content: '';
			border: 2px solid $white;
			border-radius: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&--instructor {
		position: relative;
		margin: 0;
		width: 32px;
		height: 32px;
		border: 1px solid $white;
	}
}

.gravatar-wrapper {

	&--tight {
		@include angie-spacing(margin, left, spacing-04);

		.gravatar,
		.avatar {
			margin-right: 0;
		}
	}
}

//## SQUARE  IMAGES
.sqr-img {
	width: 48px;
	height: 48px;

	&--md {
		width: 40px;
		height: 40px;
	}

	&--sm {
		width: 28px;
		height: 28px;
	}
}

//## ROUND STATUS
.round-st {
	display: flex;
	width: 36px;
	height: 36px;
	align-items: center;
	justify-content: center;
	font-size: 1.8rem;
	color: $gray;
	border-radius: 50%;
	background: $gray-pale;
}
