@font-face {
	font-family: 'Inter';
	font-weight: 100;
	font-style: normal;
	font-display: swap;
	src:
		url('../fonts/inter/Inter-Thin.woff2') format('woff2'),
		url('../fonts/inter/Inter-Thin.woff') format('woff');
}

@font-face {
	font-family: 'Inter';
	font-weight: 100;
	font-style: italic;
	font-display: swap;
	src:
		url('../fonts/inter/Inter-ThinItalic.woff2') format('woff2'),
		url('../fonts/inter/Inter-ThinItalic.woff') format('woff');
}

@font-face {
	font-family: 'Inter';
	font-weight: 200;
	font-style: normal;
	font-display: swap;
	src:
		url('../fonts/inter/Inter-ExtraLight.woff2') format('woff2'),
		url('../fonts/inter/Inter-ExtraLight.woff') format('woff');
}

@font-face {
	font-family: 'Inter';
	font-weight: 200;
	font-style: italic;
	font-display: swap;
	src:
		url('../fonts/inter/Inter-ExtraLightItalic.woff2') format('woff2'),
		url('../fonts/inter/Inter-ExtraLightItalic.woff') format('woff');
}

@font-face {
	font-family: 'Inter';
	font-weight: 300;
	font-style: normal;
	font-display: swap;
	src:
		url('../fonts/inter/Inter-Light.woff2') format('woff2'),
		url('../fonts/inter/Inter-Light.woff') format('woff');
}

@font-face {
	font-family: 'Inter';
	font-weight: 300;
	font-style: italic;
	font-display: swap;
	src:
		url('../fonts/inter/Inter-LightItalic.woff2') format('woff2'),
		url('../fonts/inter/Inter-LightItalic.woff') format('woff');
}

@font-face {
	font-family: 'Inter';
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src:
		url('../fonts/inter/Inter-Regular.woff2') format('woff2'),
		url('../fonts/inter/Inter-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Inter';
	font-weight: 400;
	font-style: italic;
	font-display: swap;
	src:
		url('../fonts/inter/Inter-Italic.woff2') format('woff2'),
		url('../fonts/inter/Inter-Italic.woff') format('woff');
}

@font-face {
	font-family: 'Inter';
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	src:
		url('../fonts/inter/Inter-Medium.woff2') format('woff2'),
		url('../fonts/inter/Inter-Medium.woff') format('woff');
}

@font-face {
	font-family: 'Inter';
	font-weight: 500;
	font-style: italic;
	font-display: swap;
	src:
		url('../fonts/inter/Inter-MediumItalic.woff2') format('woff2'),
		url('../fonts/inter/Inter-MediumItalic.woff') format('woff');
}

@font-face {
	font-family: 'Inter';
	font-weight: 600;
	font-style: normal;
	font-display: swap;
	src:
		url('../fonts/inter/Inter-SemiBold.woff2') format('woff2'),
		url('../fonts/inter/Inter-SemiBold.woff') format('woff');
}

@font-face {
	font-family: 'Inter';
	font-weight: 600;
	font-style: italic;
	font-display: swap;
	src:
		url('../fonts/inter/Inter-SemiBoldItalic.woff2') format('woff2'),
		url('../fonts/inter/Inter-SemiBoldItalic.woff') format('woff');
}

@font-face {
	font-family: 'Inter';
	font-weight: 700;
	font-style: normal;
	font-display: swap;
	src:
		url('../fonts/inter/Inter-Bold.woff2') format('woff2'),
		url('../fonts/inter/Inter-Bold.woff') format('woff');
}

@font-face {
	font-family: 'Inter';
	font-weight: 700;
	font-style: italic;
	font-display: swap;
	src:
		url('../fonts/inter/Inter-BoldItalic.woff2') format('woff2'),
		url('../fonts/inter/Inter-BoldItalic.woff') format('woff');
}

@font-face {
	font-family: 'Inter';
	font-weight: 800;
	font-style: normal;
	font-display: swap;
	src:
		url('../fonts/inter/Inter-ExtraBold.woff2') format('woff2'),
		url('../fonts/inter/Inter-ExtraBold.woff') format('woff');
}

@font-face {
	font-family: 'Inter';
	font-weight: 800;
	font-style: italic;
	font-display: swap;
	src:
		url('../fonts/inter/Inter-ExtraBoldItalic.woff2') format('woff2'),
		url('../fonts/inter/Inter-ExtraBoldItalic.woff') format('woff');
}

@font-face {
	font-family: 'Inter';
	font-weight: 900;
	font-style: normal;
	font-display: swap;
	src:
		url('../fonts/inter/Inter-Black.woff2') format('woff2'),
		url('../fonts/inter/Inter-Black.woff') format('woff');
}

@font-face {
	font-family: 'Inter';
	font-weight: 900;
	font-style: italic;
	font-display: swap;
	src:
		url('../fonts/inter/Inter-BlackItalic.woff2') format('woff2'),
		url('../fonts/inter/Inter-BlackItalic.woff') format('woff');
}

//// List of the fonts added for certificate editor purposes ////

@font-face {
	font-family: 'Akkurat';
	src: url('../fonts/certificates/Akkurat.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Akkurat';
	src: url('../fonts/certificates/AkkuratBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Arial';
	src: url('../fonts/certificates/Arial.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Arial';
	src: url('../fonts/certificates/ArialBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Calibri';
	src: url('../fonts/certificates/Calibri.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Calibri';
	src: url('../fonts/certificates/CalibriBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Comic Sans MS';
	src: url('../fonts/certificates/ComicSans.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Comic Sans MS';
	src: url('../fonts/certificates/ComicSansBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Courier New';
	src: url('../fonts/certificates/CourierNew.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Courier New';
	src: url('../fonts/certificates/CourierNewBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Georgia';
	src: url('../fonts/certificates/Georgia.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Georgia';
	src: url('../fonts/certificates/GeorgiaBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Helvetica';
	src: url('../fonts/certificates/Helvetica.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Helvetica';
	src: url('../fonts/certificates/HelveticaBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Lucida Sans Unicode';
	src: url('../fonts/certificates/LucidaSansUnicode.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Lucida Sans Unicode';
	src: url('../fonts/certificates/LucidaSansUnicodeBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/certificates/OpenSans.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/certificates/OpenSansBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Tahoma';
	src: url('../fonts/certificates/Tahoma.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Tahoma';
	src: url('../fonts/certificates/TahomaBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Times New Roman';
	src: url('../fonts/certificates/TimesNewRoman.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Times New Roman';
	src: url('../fonts/certificates/TimesNewRomanBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Trebuchet MS';
	src: url('../fonts/certificates/TrebuchetMS.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Trebuchet MS';
	src: url('../fonts/certificates/TrebuchetMSBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Verdana';
	src: url('../fonts/certificates/Verdana.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Verdana';
	src: url('../fonts/certificates/VerdanaBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

//// End of the list of the fonts added for certificate editor purposes ////

/* -------------------------------------------------------
Variable font.
Usage:

	html { font-family: 'Inter', sans-serif; }
	@supports (font-variation-settings: normal) {
		html { font-family: 'Inter var', sans-serif; }
	}
*/
@font-face {
	font-family: 'Inter var';
	font-weight: 100 900;
	font-display: swap;
	font-style: normal;
	// font-named-instance: 'Regular';
	src: url('../fonts/inter/Inter-roman.var.woff2') format('woff2');
}

@font-face {
	font-family: 'Inter var';
	font-weight: 100 900;
	font-display: swap;
	font-style: italic;
	// font-named-instance: 'Italic';
	src: url('../fonts/inter/Inter-italic.var.woff2') format('woff2');
}

@font-face {
	font-family: 'Sunrise';
	font-weight: 400;
	font-style: italic;
	font-display: swap;
	src: url('../fonts/waiting-for-the-sunrise/WaitingfortheSunrise-Regular.ttf') format('truetype');
}

body {
	position: relative;
	margin: 0;
	padding: 0;
	height: auto;
	min-height: 100%;
	color: $gray-darker;
	background-color: $white;
	//This is a font-family related settings - those needs to be reconsider when we replace 'Inter' with some other font
	font-feature-settings: 'ss01' on, 'ss03' on, 'cv11' on;

	// since theme.css (dictates branding eg bg color) is loaded before our file, we need better specificity for modifiers
	&.bg {
		background-color: $brand-primary;
		background-repeat: repeat;

		&.bg {

			&--gray-lighter {
				background-color: var(--surfaces-surface-primary);
				background-image: none;
			}

			&--white {
				background-color: $white;
				background-image: none;
			}

			&--dots {
				background-color: $white;
				background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSI4IiBjeT0iOCIgcj0iMSIgZmlsbD0iI0U0RTdFQiIvPjwvc3ZnPg==');
				background-repeat: repeat;
			}
		}
	}
}

p {
	margin: 0;
	font-size: $font-size-p;
	line-height: 1.71428571;
}

.wrap {
	position: relative;
	padding-bottom: 159px;
	min-height: 100vh;

	// Modifier for templates which use fixed header

	/**
	 * application.html.erb
	 * blling.html.erb
	 * centered-layout.html.erb
	 * course_view.html.erb
	 */
	&--fixed-hd {
		padding-top: 138px;
	}

	&--fixed-ft {
		padding-bottom: 110px;

		&--links {
			padding-bottom: 145px;
		}
	}

	/**
	 * default.layout.html
	 */

	&--no-subheader {
		padding-top: 64px;
	}

	&--action-footer {
		padding-bottom: 64px;
	}

	&--no-scroll {
		padding-bottom: 0;
		min-height: auto;
	}

	&--minimal-and-subheader {
		padding-top: 72px;
		padding-bottom: 0;
	}

	&--all-items-responsive {
		@media (max-width: $lu-screen-s) {
			padding-top: 110px;
		}
		@media (max-width: $lu-screen-xs) {
			padding-top: 150px;
		}
	}

	&--no-padding {
		padding-top: 14px;
		padding-bottom: 0;
	}
}

// Blur background when modal is open

/**
 * Class .modal-open is use for trigger to blur background when modal is open
 */
%bluryBackground {
	z-index: 0;
	will-change: transition;
	transition: blur 0.1s ease-in;
	filter: blur(4px);
}

.modal-open .wrapper,
.modal-open .main-footer,
.blur-body .blur,
.blur-body .main-footer {
	@extend %bluryBackground;
}

.middle {
	padding-top: 20px;
}

.spinner-wrap {
	position: absolute;
	z-index: 99;
	width: 100%;
	height: 100%;
	zoom: 1;
	text-align: center;
}

.spinner-bg {
	@extend %shadow;
	position: absolute;
	right: 0;
	left: 0;
	z-index: 2;
	margin: 200px auto 0 auto;
	width: 72px;
	height: 72px;
	line-height: 70px;
	border-radius: 50%;
	background: $white;
}

.spinner {
	max-width: 48px;
}

// Hide scroll on body when  Global search, Portal jumper or SCORM are opened
.hide-body-scroll {
	position: fixed;
	width: 100%;
}

.hide-scroll {
	overflow: hidden;
}

// Hide scroll on material drawer
.mat-drawer-inner-container::-webkit-scrollbar {
	display: none;
}

.hidden {
	display: none;
}
// Style for app shell
// Shell is destroyed after initial app load
.shell {
	display: flex;
	min-height: 100vh;
	flex-direction: column;

	.header {

		.mainheader {
			padding: 14px;
		}

		.sub-header {
			padding: 8px;
			height: 44px;
			background: $white;

			button {
				width: 120px;
				height: 28px;
			}
		}

		.search {
			position: relative;
			padding: 10px 170px 10px 42px;
			width: 100%;
			height: $mainheader-height;
			border: transparent;
			border-radius: $border-radius-base;
			background: $white;
		}
	}

	.content {
		flex: 1;
	}

	.main-footer {
		padding: 36px;

		svg {
			max-height: 42px;
		}
	}
}
