//## CARD MIXIN
/*
Card mixin can have two optional arguments:
- $size can have two values at the moment medium 'md' and large 'lg', that will change padding of the card
- $background can get any value hexa, variable,... recommended is to use our variable, default $background is $white
Note: without arguments mixin will print default values with all sizes.

Places where crd mixin is used:
- content-wrapper.component.scss
- rules-engine.component.scss
*/
@mixin crd($size: "", $background: $white) {

	@if $size == "" {
		$map: map-merge(map-get($crd, default),("background": $background));
		@include print-list($map);

		&--md {
			$map: map-get($crd, md);
			@include print-list($map);
		}

		&--lg {
			$map: map-get($crd, lg);
			@include print-list($map);
		}
	} @else {
		$map: map-merge(map-merge(map-get($crd, default),map-get($crd, $size)),("background": $background));
		@include print-list($map);
	}

	&--border {
		$map: map-get($crd, border);
		@include print-list($map);
	}
}



//## SIDE CARD MIXIN
/*
Side card mixin used for side-card currently used on:
- card-wrapper.component.scss
*/
@mixin side-crd() {

	$map: map-get($side-crd, "default");
	@include print-list($map);

		&--border {
			$map: map-get($side-crd, "border");
			@include print-list($map);
		}

		&__hd {
			$map: map-get($side-crd, "hd");
			@include print-list($map);
		}

		&__tl {
			$map: map-get($side-crd, "tl");
			@include print-list($map);
		}

		&__status {
			@include pill($pill);
			margin-bottom: 12px;

			&--success {
				@include pill($pill, positive);
			}
		}

		&__additional-status {
			$map: map-get($side-crd, "additional-status");
			@include print-list($map);
		}

		&__additional-status-icon {
			$map: map-get($side-crd, "additional-status-icon");
			@include print-list($map);
		}

		&__txt {
			$map: map-get($side-crd, "txt");
			@include print-list($map);
		}

		&__img {
			$map: map-get($side-crd, "img");
			@include print-list($map);
		}

}
