$node-types: (
	course: $workflow-blue,
	branch: $workflow-purple,
	certificate: $workflow-orange,
	delay: $workflow-red
);

$shades: (
	course-pale: $workflow-blue-pale,
	branch-pale: $workflow-purple-pale,
	certificate-pale: $workflow-orange-pale,
	delay-pale: $workflow-red-pale
);

$expression-categories: (
	undefined: $gray-pale,
	previous_enrollment: $workflow-green,
	custom_user_data: $workflow-cud-badge,
);

$expression-categories-expanded: (
	previous_enrollment: $workflow-action-green,
	custom_user_data: $workflow-secondary-brand-purple,
);

$expression-categories-expanded-outlines: (
	previous_enrollment_outline: $workflow-action-green-outline,
	custom_user_data_outline: $workflow-cud-outline,
);

$branch-styles: (
	unset: $white,
	one-prev-enrollment: $workflow-action-green,
	one-cud: $workflow-cud-background,
	multi: $workflow-branch-multi-background
);

/**
 *  Mixin used to print colors for all types of Nodes
 */

/** Arguments for mixin
	- $property node, header, icon
*/
@mixin nodeColor($property) {
	@each $type, $color in $node-types {

		@if $property == node {
			&--#{$type} {
				border-color: $color;
			}
		}

		@else if $property == header {
			&--#{$type} {
				border-radius: $node-border-radius $node-border-radius 0 0;
				background: map-get($shades, #{$type}-pale);
			}
		}

		@else if $property == icon {
			&--#{$type} {
				--icon-fill: #{$color};
			}
		}
	}
}

/**
 *  Mixin used to print colors for all expression types
 */
 @mixin branchStyles() {
    @each $type, $color in $branch-styles {
        &--#{$type} {
            border-color: if($type == 'unset', $gray-base, $color);
            background-color: $color;
        }
    }
}

/**
 *  Mixin used to print colors for all expression types
 */
@mixin expressionCategoryColors() {
	@each $type, $color in $expression-categories {
		

		&--#{$type} {
			background-color: #{$color};
		}
	}
}

/**
 *  Mixin used to print colors for expanded branch view
 */
@mixin expressionCategoryColorsExpanded() {
	@each $type, $color in $expression-categories-expanded {

		&--#{$type} {
			background-color: #{$color};
			border: 1px solid map-get($expression-categories-expanded-outlines, #{$type}_outline);
		}
	}
}
