
// Dropdown arrow/caret
.caret {
	display: inline-block;
	margin-left: 2px;
	width: 0;
	height: 0;
	vertical-align: middle;
	border-top: $caret-width-base dashed;
	border-right: $caret-width-base solid transparent;
	border-left: $caret-width-base solid transparent;
}

// The dropdown wrapper (div)
.dropup,
.dropdown {
	position: relative;
}

// Prevent the focus on the dropdown toggle when closing dropdowns
.dropdown-toggle:focus {
	outline: 0;
}

// The dropdown menu (ul)
.dropdown-menu {
	@extend %elevation3;
	position: absolute;
	top: 100%;
	left: 0;
	z-index: $zindex-dropdown;
	display: none; // none by default, but block on "open" of the menu
	float: left;
	margin: 0;
	min-width: 160px;
	font-size: $font-size-base;
	text-align: left;
	list-style: none;
	border: 1px solid $dropdown-border;
	border-radius: $border-radius-base;
	background-color: $dropdown-bg;
	background-clip: padding-box;

	// Aligns the dropdown menu to right
	&.pull-right {
		right: 0;
		left: auto;
	}

	// Dividers (basically an hr) within the dropdown
	.divider {
		@include divider($dropdown-divider-bg);
	}

	// Links within the dropdown menu
	& > li > a {
		display: block;
		padding: 3px 20px;
		clear: both;
		font-weight: $regular;
		line-height: $line-height-base;
		white-space: nowrap;
		color: $dropdown-link-color;
	}
}

// Hover/Focus state
.dropdown-menu > li > a,
.dropdown-menu > li > .btn--block {

	&:hover,
	&:focus {
		text-decoration: none;
		color: $dropdown-link-hover-color;
		background-color: $dropdown-link-hover-bg;
	}
}

// Active state
.dropdown-menu > .active > a,
.dropdown-menu > .active > .btn--block {

	&,
	&:hover,
	&:focus {
		text-decoration: none;
		color: $dropdown-link-active-color;
		outline: 0;
		background-color: $dropdown-link-active-bg;
	}
}

// Disabled state
//
// Gray out text and ensure the hover/focus state remains gray

.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > .btn--block {

	&,
	&:hover,
	&:focus {
		color: $dropdown-link-disabled-color;
	}

	// Nuke hover/focus effects
	&:hover,
	&:focus {
		text-decoration: none;
		cursor: $cursor-disabled;
		background-color: transparent;
		background-image: none; // Remove CSS gradient
	}
}

// Open state for the dropdown
.open {
	// Show the menu
	> .dropdown-menu {
		display: block;
	}

	// Remove the outline when :focus is triggered
	> a {
		outline: 0;
	}
}

// Backdrop for dropdown-menu
.open .overlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 3;
	width: 100%;
	height: 100vh;
	min-height: 110vh;
	content: '';
	background: rgba(0, 0, 0, 0.36);
}

/**
 *  Menu positioning
 *
 *  Add extra class to `.dropdown-menu` to flip the alignment of the dropdown
 *  menu with the parent
 */

// Align dropdown-menu to right

.dropdown-menu-right {
	right: 0 !important;
	left: auto !important; // Reset the default from `.dropdown-menu`
}

// Align dropdown-menu to left
.dropdown-menu-left {
	right: auto !important;
	left: 0 !important;
}

// Align dropdown-menu to center
.dropdown-menu-center {
	right: 0 !important;
	left: 0 !important;
	float: none;
	margin: 0 auto;
}

.dropdown-menu {

	&__hd {

		&--xs {
			display: none;
		}
	}

	&--main-nav {
		top: -3px;
		left: -2px;
		padding: 0;
	}

	&--sub-nav {
		top: -3px;
		right: 0;
		left: 0;
		float: none;
		margin: 0 auto;
		padding: 18px;
		width: 240px;
	}

	&--action-nav {
		top: -3px;
		right: -2px;
		left: auto;
		margin: 0;
		padding: 18px;
	}
}

// Fix size of dropdown menu
.dropdown-fix-width {
	position: relative;
}

.typeahead .dropdown-menu,
.fix-height .dropdown-menu,
.dropdown-fix-width .dropdown-menu {
	padding: 18px 12px;
	width: 100%;
	max-width: 100%;
	max-height: 360px;
	overflow: auto;

	& a {
		padding: 3px 6px;
	}

	& a div {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

// Workaround for making typeahead fixed height when always-open mode is on
.typeahead {

	&--fixed-height {

		.result-list--ddm-open {
			min-height: 27em;
		}
	}
}

// Dropdown section headers
.dropdown-header {
	display: block;
	padding: 3px 20px;
	font-size: $font-size-small;
	line-height: $line-height-base;
	white-space: nowrap;
	color: $dropdown-header-color;
}

// Backdrop to catch body clicks on mobile, etc.
.dropdown-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: ($zindex-dropdown - 10);
}

// Right aligned dropdowns
.pull-right > .dropdown-menu {
	right: 0;
	left: auto;
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
//
// Just add .dropup after the standard .dropdown class and you're set, bro.
// TODO: abstract this so that the navbar fixed styles are not placed here?

.dropup,
.navbar-fixed-bottom .dropdown {
	// Reverse the caret
	.caret {
		content: '';
		border-top: 0;
		border-bottom: $caret-width-base dashed;
	}
	// Different positioning for bottom up menu
	.dropdown-menu {
		top: auto;
		bottom: 100%;
		margin-bottom: 2px;
	}
}

// Component alignment
//
// Reiterate per navbar.less and the modified component alignment there.

@media (min-width: $grid-float-breakpoint) {

	.navbar-right {

		.dropdown-menu {
			right: 0;
			left: auto;
		}

		// Necessary for overrides of the default right aligned menu.
		// Will remove come v4 in all likelihood.
		.dropdown-menu-left {
			right: auto;
			left: 0;
		}
	}
}

@media (max-width: $lu-screen-xs-max) {

	.responsive-dropdown {
		position: fixed;
		top: 2vh;
		right: 2%;
		left: 2%;
		z-index: 9999;
		width: 96%;
		height: 96vh;
		overflow: auto;
	}

	.responsive-dropdown-header {
		display: block;
		padding: 12px;
		min-height: 48px;
		border-bottom: 1px $gray-light solid;
	}

	.responsive-dropdown-close {
		float: right;
		font-size: 1.8rem;
		line-height: 24px;
	}

	.dropdown-menu {

		&__hd {

			&--xs {
				display: block;
				padding: 12px;
				min-height: 48px;
				border-bottom: 1px $gray-light solid;
			}
		}

		&__close-btn {
			float: right;
			font-size: 1.8rem;
			line-height: 24px;
		}

		&--xs {
			position: fixed;
			top: 2vh;
			right: 2%;
			left: 2%;
			z-index: 9999;
			width: 96%;
			height: 96vh;
			overflow: auto;
		}
	}
}
