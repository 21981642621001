@import 'mixins';

.menu-trigger {
	border: 0;
	background: none;
}

.menu-content {
	@extend %elevation3;
	display: flex;
	padding: 0;
	flex-direction: column;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: $border-radius-base;
	background: $white;
	background-clip: padding-box;
}

.menu-item {
	padding: $spacing-12 $spacing-16;
	font-weight: $regular;
	line-height: $line-height-base;
	text-align: left;
	white-space: nowrap;
	color: $dropdown-link-color;
	border: 0;
	background: none;

	&:hover {
		color: $dropdown-link-hover-color;
		background-color: $dropdown-link-hover-bg;
	}
}
