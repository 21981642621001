@import 'variables';
@import 'mixins';
@import 'core';
@import 'larger';
@import 'fixed-width';
@import 'list';
@import 'bordered-pulled';
@import 'animated';
@import 'rotated-flipped';
@import 'stacked';
@import 'icons';
// @import 'screen-reader';

// REMOVE THIS LINE WHEN WE MIGRATE TO v5
@font-face {
  font-family: 'Font Awesome 4 Solid';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/font-awesome-4/fa-solid-400.eot');
  src: url('../../fonts/font-awesome-4/fa-solid-400.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/font-awesome-4/fa-solid-400.woff2') format('woff2'),
  url('../../fonts/font-awesome-4/fa-solid-400.woff') format('woff'),
  url('../../fonts/font-awesome-4/fa-solid-400.ttf') format('truetype'),
  url('../../fonts/font-awesome-4/fa-solid-400.svg#fontawesome') format('svg');
}

.fas {
  font-family: 'Font Awesome 4 Solid';
  font-weight: 400;
}

// ENDLINE
