// Flex box  aligment
.o-flex {
	display: flex;

	&--vcenter {
		align-items: center;
	}

	&--vstart {
		align-items: flex-start;
	}

	&--vend {
		align-items: flex-end;
	}

	&--vbaseline {
		align-items: baseline;
	}

	&--space-between {
		justify-content: space-between;
	}

	&--space-around {
		justify-content: space-around;
	}

	&--center {
		justify-content: center;
	}

	&--stretch {
		align-items: stretch;
	}

	&--flex-end {
		justify-content: flex-end;
	}

	&--flex-start {
		justify-content: flex-start;
	}

	&--column {
		flex-direction: column;
	}

	&--flex-center {
		justify-content: center;
	}

	&--self-start {
		align-self: flex-start;
	}

	&--self-center {
		align-self: center;
	}

	&--self-end {
		align-self: flex-end;
	}

	&--wrap {
		flex-wrap: wrap;
	}
}

// shortens the most oftenly used class:
// 'o-flex o-flex--vcenter o-flex--flex-center'
.o-flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.o-flex1 {
	flex: 1;
}

.o-flex2 {
	flex: 2;
}

.o-flex3 {
	flex: 3;
}

.o-flex0 {
	flex: 0 0 auto;
}

.o-flex3,
.o-flex2,
.o-flex1,
.o-flex0 {

	&--ellipsis-cntr {
		min-width: 0;
		max-width: 100%;
	}
}

.order-1 {
	order: 1;
}
