.pill-default {
	@include angie-pill($angie-pill-default, default);

	&--plain {
		@include angie-pill($angie-pill-default, plain);
	}

	&--base {
		@include angie-pill($angie-pill-default, base);
	}

	&--success {
		@include angie-pill($angie-pill-default, success);
	}

	&--success-light {
		@include angie-pill($angie-pill-default, success-light);
	}

	&--danger-light {
		@include angie-pill($angie-pill-default, danger-light);
	}

	&--warning {
		@include angie-pill($angie-pill-default, warning);
	}

	&--warning-light {
		@include angie-pill($angie-pill-default, warning-light);
	}

	&--progress-light {
		@include angie-pill($angie-pill-default, progress-light);
	}

	&--pending {
		@include angie-pill($angie-pill-default, pending);
	}
}

.pill-outlined {
	@include angie-pill($angie-pill-outlined, outlined);

	&--progress {
		@include angie-pill($angie-pill-outlined, progress);
	}

	&--danger {
		@include angie-pill($angie-pill-outlined, danger);
	}

	&--info {
		@include angie-pill($angie-pill-outlined, info);
	}
}
