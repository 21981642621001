
// ## VIDEO-RESPONSIVE

.video-responsive {
	position: relative;
	padding-top: 30px;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
}

.video-responsive iframe,
.video-responsive object,
.video-responsive embed {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 100% !important;
}

audio[controls] {
	width: 100%;
}

.video-js.vjs-audio {
	padding-top: 0;
	height: 0;
	min-height: 3em;
	background-color: transparent;
}

.link-av-download {
	display: inline-block;
	padding-top: 12px;
	padding-bottom: 18px;
}
