@function valueCheck($type) {

	@each $key, $value in $spacing-obj {
			@if $key == $type {
					@return $value;
			}
	}

	@return null;
}



// spacing mixin is used for dynamic padding or margin
/** Arguments for mixin
- $value spacing-extra-tigh, spacing-tight, spacing, spacing-loose, spacing-extra-loose, section-spacing, section-loose or section-extra-loose
- $minus can be number
*/
@mixin section-spacing($value, $minus: 0) {

// Check for error condition if null will show you error
	$new-value: valueCheck($value);

	@if $new-value != null {
			 margin-bottom: $new-value - $minus;
	}@else {
			@error 'Argument #{$value} must be eather spacing-extra-tigh, spacing-tight, spacing, spacing-loose, spacing-extra-loose, section-spacing, section-loose or section-extra-loose';
	}
}



// spacing mixin is used for dynamic padding or margin
/** Arguments for mixin
- $type: padding, margin
- $side: top, right, bottom, left, x, y, all
- $value spacing-extra-tigh, spacing-tight, spacing, spacing-loose, spacing-extra-loose, section-spacing, section-loose or section-extra-loose
- $minus can be number
*/
@mixin spacing($type, $side, $value, $minus: 0) {

	// Check for error condition if null will show you error
	$new-value: valueCheck($value);

	@if $type != padding and $type != margin {
			@error "Argument #{$type} must be either padding or margin.";
	}@else if $side != top and $side != right and $side != bottom and $side != left and $side != x and $side != y and $side != all {
			@error "Argument #{$side} must be either top, right, bottom, left, x, y or all.";
	}@else if $value == null {
			@error 'Argument #{$type} must be eather spacing-extra-tigh, spacing-tight, spacing, spacing-loose, spacing-extra-loose, section-spacing, section-loose or section-extra-loose';
	}

	@if $side == all {
			#{$type}: $new-value - $minus;
	}@else if $side == y {
			#{$type}-top: $new-value - $minus;
			#{$type}-bottom: $new-value - $minus;
	}@else if $side == x {
			#{$type}-left: $new-value - $minus;
			#{$type}-right: $new-value - $minus;
	}@else {
			#{$type}-#{$side}: $new-value - $minus;
	}
}
