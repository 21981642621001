$headings: (
	'h1': (
		'font-size': $font-size-h1, // font-size: 26px;
		'font-weight': $medium,
		'line-height': 1.38461538462, // line-height: 36px;
	),
	'h2': (
		'font-size': $font-size-h2, // font-size: 23px;
		'font-weight': $medium,
		'line-height': 1.39130434783, // line-height: 32px;
		'letter-spacing': 0
	),
	'h3': (
		'font-size': $font-size-h3, // font-size: 20px;
		'font-weight': $medium,
		'line-height': 1.42857142857, // line-height: 30px;
		'letter-spacing': 0
	),
	'h4': (
		'font-size': $font-size-h4, // font-size: 18px;
		'font-weight': $medium,
		'line-height': 1.33333333333, // line-height: 24px;
		'letter-spacing': 0
	),
	'h5': (
		'font-size': $font-size-h5, // font-size: 16px;
		'font-weight': $regular,
		'line-height': 1.5, // line-height: 24px;
		'letter-spacing': 0
	),
	'h6': (
		'font-size': $font-size-h6, // font-size: 14px;
		'font-weight': $medium,
		'line-height': 1.42857143, // line-height: 20px;
		'letter-spacing': 0
	)
);
