// ## TOASTR

.toast-message {
	-ms-word-wrap: break-word;
	word-wrap: break-word;
}

.toast-success,
.toast-badge {
	background-color: $state-success-bg;
}

.toast-success {
	background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 24 24\' version=\'1.1\' id=\'Layer_1\' enable-background=\'new 0 0 24 24\' xml:space=\'preserve\' width=\'24px\' height=\'24px\'%3E%3Cpath fill=\'rgb(255,255,255)\' d=\'m 18,1.6 c 1.8,1.1 3.3,2.5 4.4,4.4 1.1,1.8 1.6,3.8 1.6,6 0,2.2 -0.5,4.2 -1.6,6 -1.1,1.8 -2.5,3.3 -4.4,4.4 C 16.1,23.5 14.2,24 12,24 9.8,24 7.8,23.5 6,22.4 4.2,21.3 2.7,19.8 1.6,18 0.5,16.2 0,14.2 0,12 0,9.8 0.5,7.8 1.6,6 2.7,4.2 4.2,2.7 6,1.6 7.8,0.5 9.8,0 12,0 c 2.2,0 4.2,0.5 6,1.6 z M 17.3,21 c 1.6,-0.9 2.9,-2.2 3.8,-3.8 0.9,-1.6 1.4,-3.4 1.4,-5.3 C 22.5,10 22,8.3 21,6.8 20.1,5.2 18.8,3.9 17.2,3 15.6,2.1 13.9,1.5 12,1.5 10.1,1.5 8.3,2 6.7,3 5.1,4 3.9,5.2 3,6.8 2.1,8.4 1.5,10.1 1.5,12 c 0,1.9 0.5,3.7 1.5,5.2 1,1.5 2.2,2.9 3.8,3.8 1.6,0.9 3.4,1.4 5.2,1.4 1.9,0.1 3.7,-0.4 5.3,-1.4 z M 19,8.8 C 19,8.6 19,8.5 18.9,8.4 L 18.5,8 C 18.4,7.9 18.3,7.8 18.1,7.8 c -0.2,0 -0.3,0 -0.4,0.1 L 9.8,15.8 6.5,12.4 C 6.4,12.3 6.2,12.3 6.1,12.3 6,12.3 5.8,12.3 5.7,12.4 L 5.3,12.8 C 5,12.9 5,13 5,13.2 c 0,0.2 0,0.3 0.1,0.4 l 4.2,4.2 C 9.4,17.9 9.6,18 9.7,18 9.8,18 10,17.9 10.1,17.8 L 18.9,9.1 C 19,9 19,8.9 19,8.8 Z\'/%3E%3C/svg%3E');
}

.toast-error {
	background-color: $state-danger-bg;
	background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 576 512\' width=\'24px\' height=\'24px\'%3E%3Cpath fill=\'rgb(255,255,255)\' d=\'M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.054-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.952 83.154 0l239.94 416.028zm-27.658 15.991l-240-416c-6.16-10.678-21.583-10.634-27.718 0l-240 416C27.983 466.678 35.731 480 48 480h480c12.323 0 19.99-13.369 13.859-23.996zM288 372c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28zm-11.49-212h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM288 372c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z\'/%3E%3C/svg%3E');
}

.toast-info {
	background-color: $state-success-bg;
	background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 512 512\' width=\'24px\' height=\'24px\'%3E%3Cpath fill=\'rgb(255,255,255)\' d=\'M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z\'/%3E%3C/svg%3E');
}

.toast-warning {
	background-color: $state-warning-bg;
	background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 512 512\' width=\'24px\' height=\'24px\'%3E%3Cpath fill=\'rgb(255,255,255)\' d=\'M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z\'/%3E%3C/svg%3E');
}

#toast-container {
	position: fixed;
	right: 12px;
	bottom: 12px;
	z-index: 1000000;
	width: 420px;
}

#toast-container > div {
	@extend %elevation4;
	margin-top: 12px;
	padding: 24px 32px 24px 60px;
	color: $white;
	border-radius: 4px;
	background-repeat: no-repeat;
	background-position: 18px 24px;
}

#toast-container > div.toast-badge {
	margin-top: 18px;
	padding: 24px 32px 24px 24px;
}

#toast-container > :hover {
	cursor: pointer;
	-webkit-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.42);
	-moz-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.42);
	box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.42);
}

#toast-container > .toast-badge {
	background-size: 24px;
}

.toast-title {
	font-weight: $regular;
}

.toast {
	word-break: normal;
	background-color: $brand-primary;
}

.toast-close-button {
	position: relative;
	top: -0.3em;
	right: -18px;
	float: right;
	font-size: 2rem;
	color: $white;
	opacity: 0.8;
	text-shadow: 0 1px 0 $white;
	filter: alpha(opacity=80);
}

.toast-close-button:hover,
.toast-close-button:focus {
	text-decoration: none;
	color: $black;
	cursor: pointer;
	opacity: 0.4;
	filter: alpha(opacity=40);
}

// Additional properties for button version

/**
 * requires the button element instead of an anchor tag.
 * If you want the anchor version, it requires `href="#"`.
 */
button.toast-close-button {
	padding: 0;
	cursor: pointer;
	border: 0;
	background: transparent;
	-webkit-appearance: none;
}
