.horizontal .form-group div {
	@include angie-spacing(padding, right, spacing-12);
	display: flex;
}

.form-control-item {

	&--shrink {

		// we're safe to extend this with any field we need but input will be mostly used for components like "duration", "minutes" etc...
		input {
			min-width: 50px;
			max-width: 60px;
		}
	}

	// maybe we won't need margin bottom for some form field components, overwrite it
	&--no-margin {

		.form-group {
			margin-bottom: 0;
		}
	}
}

.form-section {

	&__crd {
		@include angie-spacing(padding, x, spacing-16);
		@include angie-spacing(padding, top, spacing-16);
		@include angie-spacing(padding, bottom, spacing-04);
		@include angie-spacing(margin, bottom, spacing-24);

		border-radius: $border-radius-base;
		background-color: var(--surfaces-surface-primary);
	}

	&__tl {
		@include angie-spacing(margin, bottom, spacing-24);
		line-height: 1;
	}

	&__sub-tl {
		@include angie-spacing(margin, bottom, spacing-12);
		line-height: 1;
	}

	&__separator {
		@include angie-spacing(margin, bottom, spacing-24);
		@include angie-spacing(margin, top, spacing-08);
		border-top-color: $gray-pale;
	}

	&__txt {
		@include angie-spacing(margin, top, spacing-08);
		@include angie-spacing(padding, bottom, spacing-16);
	}

	&__no_label {

		.custom-radio:first-child,
		.custom-checkbox:first-child {
			margin-top: 0; // we need to cover usecase where we got label missing but instead we have section title
		}
	}

	&__additional {
		@include angie-spacing(margin, bottom, spacing-08);
	}

	&__info-wrapper {
		@include spacing(margin, top, 0, 0.5rem);
		@include angie-spacing(margin, bottom, spacing-16);

		&__ico {
			@include angie-spacing(margin, right, spacing-04);
		}
	}
}
