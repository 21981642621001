// mixin badge used for typeahead
@mixin badge() {
	display: flex;
	margin: 3px;
	padding: 6px;
	max-width: 100%;
	align-items: center;
	font-size: 1.4rem;
	font-weight: $medium;
	line-height: 16px;
	border: 1px solid $gray-light;
	border-radius: $border-radius-base;
	background: $gray-lighter;
	animation: scaleIn 0.2s linear;

	& .lui {
		top: 0;
		color: $gray;
		cursor: pointer;
	}

}

// Pill/label mixin
/*
	Mixin require $map parametar and optional parametar for $modifier
	Pill mixin used for:
	- chat-integration
	- dynamic rules (enable/disable status)
	- dynamic rules (and relation between criteria conditions)
*/
@mixin pill ($map, $modifier: ""){

	@if $modifier == "" {
		$pill-map: map-get($map, 'default');
		@include print-list($pill-map);
	}	@else if map-has-key($map, $modifier) {
		$pill-map: map-get($map, $modifier);
		@include print-list($pill-map);
	} @else {
		@error "Property #{$modifier} must be one of: #{map-keys($map)}.";
	}

}
