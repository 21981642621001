/* stylelint-disable indentation */
$angie-headings: (
	'xxl': (
		'font-size': $fs-heading-xxl, // font-size: 32px;
		'font-weight': $fw-semi-bold,
		'line-height': 1.375, // line-height: 44px;
		'color': $black, // #0A1926 object-primary
	),
	'xl': (
		'font-size': $fs-heading-xl, // font-size: 25px;
		'font-weight': $fw-semi-bold,
		'line-height': 1.28, // line-height: 32px;
		'color': $black, // #0A1926 object-primary
	),
	'l': (
		'font-size': $fs-heading-l, // font-size: 20px;
		'font-weight': $fw-semi-bold,
		'line-height': 1.4, // line-height: 28px;
		'color': $black, // #0A1926 object-primary
	),
	'm': (
		'font-size': $fs-heading-m, // font-size: 18px;
		'font-weight': $fw-medium,
		'line-height': 1.333, // line-height: 24px;
		'color': $black, // #0A1926 object-primary
	),
	's': (
		'font-size': $fs-heading-s, // font-size: 16px;
		'font-weight': $fw-medium,
		'line-height': 1.125, // line-height: 18px;
		'color': $black, // #0A1926 object-primary
	),
	'xs': (
		'font-size': $fs-heading-xs, // font-size: 14px;
		'font-weight': $fw-medium,
		'line-height': 1.286, // line-height: 18px;
		'color': $black, // #0A1926 object-primary
	)
);
$angie-txt-weight: (
	'regular': $fw-regular, // 400
	'medium': $fw-medium, // 500
	'semi-bold': $fw-semi-bold, // 600
);
