/**
 *  Mixin used to print values for tags h1-h6 and class .fs-h1-.fs-h6,
 *  Can be used as @include to custom class with optional argument h1-h6
 */
@mixin angie-headings() {
	@each $heading, $values in $angie-headings {
		#{$heading},
		.h-#{$heading} {
			@include print-list($values);
		}
	}
}

@mixin angie-txt-variants() {
	@each $key, $value in $angie-txt-weight {
		.#{$key} {
			font-weight: #{$value};
		}
	}
}
