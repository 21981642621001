@mixin angie-pill ($map, $modifier: "") {
	@if $modifier == "" {
		$pill-map: map-get($map, 'default');
		@include print-list($pill-map);
	}
	@else if map-has-key($map, $modifier) {
		$pill-map: map-get($map, $modifier);
		@include print-list($pill-map);
	}
	@else {
		@error "Property #{$modifier} must be one of: #{map-keys($map)}.";
	}
}
