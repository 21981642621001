// Modifier funxtion, based on argument it returns appropriate objects map.
@function getModifier($modifier, $map) {

	@if $modifier == '' {
		@return map-get($map, 'default');
	}

	@else {
		@return map-merge(map-get($map, 'default'),map-get($map, $modifier));
	}

}



// o-list mixin is used to style lists, it can have arguments
/** Defaulte argument its "", Arguments that can be used:
 *  col > changes flex direction to column
 *  dot > argument for metadata
*/
@mixin o-list ($modifier: "", $map: $o-list){

	@if $modifier == "" {
		$o-list: getModifier('', $map);
		@include print-list($o-list);
	}

	@else {
		$o-list: getModifier($modifier, $map);
		@include print-list($o-list);
	}

}



// This mixin is used for chiled o-list elements or "items"
/** Defaulte argument its "", Arguments that can be used:
 *  has-border-bottom > add border bottom to list elements
 *  dot > argument for metadata
*/
@mixin o-list__item($modifier: "", $map: $o-list__item) {

	@if $modifier == "" {
		$o-list__item: getModifier('', $map);
		@include print-list($o-list__item);
	}

	@else if $modifier == 'dot' {

		$o-list__item: getModifier('', $map);
		@include print-list($o-list__item);
		$this: &; // this represents o-list__item (selector where mixin is called)

		& + #{$this}:before {
			$o-list__item--dot: getModifier($modifier, $map);
			@include print-list($o-list__item--dot);
		}

		&.ellipsis {
			$o-list__item--ellipsis: getModifier('ellipsis', $map);
			@include print-list($o-list__item--ellipsis);
		}

	}

	@else if $modifier == 'has-border-bottom' {

		$o-list__item: getModifier($modifier, $map);
		@include print-list($o-list__item);

		&:last-child {
			border-bottom: none;
		}

	}

}
