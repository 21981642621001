/* stylelint-disable-next-line at-rule-no-unknown */
@use 'sass:math';

@function angievaluecheck($type) {
	@each $key, $value in $angie-spacing-obj {
		@if $key == $type {
			@return $value;
		}
	}
	@return null;
}

// spacing mixin is used for dynamic padding or margin

/** Arguments for mixin
- $value spacing-04, spacing-08, spacing-12, spacing-16, spacing-24, spacing-32, spacing-40, spacing-48, spacing-64,spacing-96
 - $minus can be number
*/
@mixin angie-section-spacing($value, $minus: 0) {

	// Check for error condition if null will show you error
	$new-value: angievaluecheck($value);
	@if $new-value != null {
		margin-bottom: $new-value - pixeltorem($minus);
	}
	@else {
		@error 'Argument #{$type} must be either - spacing-04, spacing-08, spacing-12, spacing-16, spacing-24, spacing-32, spacing-40, spacing-48, spacing-64,spacing-96';
	}
}

// function that converts 'px' to 'rem' with respect to the coefficient

/** Arguments for mixin
 - $pixels: number of pixels
 - $font-size: integer; size of the font, defaults to 16;
*/
@function pixeltorem($pixels, $font-size: 16) {
	@return math.div($pixels, $font-size) * $fs-base-coefficient;
}

// spacing mixin is used for dynamic padding or margin

/** Arguments for mixin
 - $type: padding, margin
 - $side: top, right, bottom, left, x, y, all
 - $value spacing-04, spacing-08, spacing-12, spacing-16, spacing-24, spacing-32, spacing-40, spacing-48, spacing-64,spacing-96
 - $minus can be number
*/
@mixin angie-spacing($type, $side, $value, $minus: 0) {

	// Check for error condition if null will show you error
	$new-value: angievaluecheck($value);
	@if $type != padding and $type != margin {
		@error "Argument #{$type} must be either padding or margin.";
	}
	@else if $side != top and $side != right and $side != bottom and $side != left and $side != x and $side != y and $side != all {
		@error "Argument #{$side} must be either top, right, bottom, left, x, y or all.";
	}
	@else if $value == null {
		@error 'Argument #{$type} must be either - spacing-04, spacing-08, spacing-12, spacing-16, spacing-24, spacing-32, spacing-40, spacing-48, spacing-64,spacing-96';
	}
	@if $side == all {
		#{$type}: $new-value - pixeltorem($minus);
	}
	@else if $side == y {
		#{$type}-top: $new-value - pixeltorem($minus);
		#{$type}-bottom: $new-value - pixeltorem($minus);
	}
	@else if $side == x {
		#{$type}-left: $new-value - pixeltorem($minus);
		#{$type}-right: $new-value - pixeltorem($minus);
	}
	@else {
		#{$type}-#{$side}: $new-value - pixeltorem($minus);
	}
}
