@import 'mixins';

// ## Hidden helper class
@each $type, $sizes in $hdn-breakpoint {
	@each $size, $value in map-get($hdn-breakpoint, $type) {

		.hdn-#{$type}-#{$size} {
			@include hidden($type,$size);
		}
	}
}

// Disabled helper
.disabled {
	cursor: $cursor-disabled;
	opacity: $disabled-opacity;
}

// Forces an element to be hidden
.hdn {
	display: none;
}

.mb-12 {
	display: block;
	margin-bottom: 12px;
}

.text-uppercase {
	text-transform: uppercase;
}

.text-center {
	text-align: center;
}

// Show required star on label
.asterisk label::after {
	color: $brand-red;
	content: '*';
}

.formly-text-only {
	margin-left: -12px;
}
