//## Breakpoint object used for mixins
/*
_helper.scss - used for hidden helper class
_c-buttons/_mx-c-buttons - used type 'to' for button with icon
*/
$hdn-breakpoint:(
	'from':(
		'xs': $lu-screen-xs, // min-width: 480px;
		'sm': $lu-screen-s, // max-width: 840px;
		'md': $lu-screen-m, // max-width: 1024px;
		'lg': $lu-screen-l, // max-width: 1280px;
		'xl': $lu-screen-xl // max-width: 1608px;
	),
	'to':(
		'xxs': $lu-screen-xxs-max, // width: 479.98px;
		'xs': $lu-screen-xs-max, // max-width: 839.98px;
		'sm': $lu-screen-s-max, // max-width: 1023.98px;
		'md': $lu-screen-m-max, // max-width: 1279.98px;
		'lg': $lu-screen-l-max // max-width: 1599.98px;
	),
);
