@import '../_variables.scss';

.tooltip {
	position: absolute;
	z-index: $zindex-tooltip;
	display: block;
	min-width: 130px;
	font-size: 1.2rem;
	word-wrap: break-word;
	color: $gray-lighter;
	opacity: 0;

	&.show,
	&.in {
		opacity: $tooltip-opacity;
	}

	.tooltip-arrow {
		position: absolute;
		display: block;
		width: $tooltip-arrow-width;
		height: $tooltip-arrow-height;

		&::before {
			position: absolute;
			content: '';
			border-style: solid;
			border-color: transparent;
		}
	}
}

.bs-tooltip-top {
	padding: $tooltip-arrow-height 0;

	.tooltip-arrow {
		bottom: 0;

		&::before {
			top: 0;
			border-width: $tooltip-arrow-height ($tooltip-arrow-width * 0.5) 0;
			border-top-color: $tooltip-arrow-color;
		}
	}
}

.bs-tooltip-right {
	padding: 0 $tooltip-arrow-height;

	.tooltip-arrow {
		left: 0;
		width: $tooltip-arrow-height;
		height: $tooltip-arrow-width;

		&::before {
			right: 0;
			border-width: ($tooltip-arrow-width * 0.5) $tooltip-arrow-height ($tooltip-arrow-width * 0.5) 0;
			border-right-color: $tooltip-arrow-color;
		}
	}
}

.bs-tooltip-bottom {
	padding: $tooltip-arrow-height 0;

	.tooltip-arrow {
		top: 0;

		&::before {
			bottom: 0;
			border-width: 0 ($tooltip-arrow-width * 0.5) $tooltip-arrow-height;
			border-bottom-color: $tooltip-arrow-color;
		}
	}
}

.bs-tooltip-left {
	padding: 0 $tooltip-arrow-height;

	.tooltip-arrow {
		right: 0;
		width: $tooltip-arrow-height;
		height: $tooltip-arrow-width;

		&::before {
			left: 0;
			border-width: ($tooltip-arrow-width * 0.5) 0 ($tooltip-arrow-width * 0.5) $tooltip-arrow-height;
			border-left-color: $tooltip-arrow-color;
		}
	}
}

.bs-tooltip-auto {

	&[x-placement^='top'] {
		@extend .bs-tooltip-top;
	}

	&[x-placement^='right'] {
		@extend .bs-tooltip-right;
	}

	&[x-placement^='bottom'] {
		@extend .bs-tooltip-bottom;
	}

	&[x-placement^='left'] {
		@extend .bs-tooltip-left;
	}
}

// Wrapper for the tooltip content
.tooltip-inner {
	padding: $tooltip-padding-y $tooltip-padding-x;
	max-width: $tooltip-max-width;
	text-align: center;
	color: $tooltip-color;
	border-radius: $border-radius-base;
	background-color: $tooltip-bg;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
