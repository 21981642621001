@import '../variables/var-core';

@mixin icon-sizes {
	@each $size-name, $size-value in $icon-sizes {
		.icon-#{$size-name} {
			@include icon-size($size-name, $size-value);
		}
	}
}

@mixin icon-size($size-name, $size-value:'') {
	@if $size-value == '' {
		$size-value: map-get($icon-sizes, $size-name);
	}
	--icon-width: #{$size-value};
	--icon-height: #{$size-value};
}

@mixin icon-color($color) {
	--icon-fill: #{$color};

	& svg {
		fill: #{$color};
	}
}
