/* You can add global styles to this file, and also import other style files */

@import '../../../../common/styles/design-tokens';

/* $asset_path_prefix: "<%= asset_path_prefix %>"; */
$asset_path_prefix: "";

// Third Party Styles
// @import 'ngx-toastr/toastr';

@import 'mixins/mx-mat-global';

@include mat-global();

//Globally-available variables and config.
@import 'variables';
@import './angie/variables';
@import 'mixins';
@import './angie/mixins';
@import 'normalize';
@import 'helper';
@import 'core';
@import 'objects';
@import 'components';
@import './angie/components';
@import 'font-awesome/fontawesome-pro-core.scss';
@import 'font-awesome/fontawesome-pro-regular.scss';

// Processes our utility classes
@import 'utils';
