@import 'mixins';

// Unordered and Ordered lists
ul,
ol {
	margin-top: 0;
	margin-bottom: ($line-height-computed * 0.5);

	ul,
	ol {
		margin-bottom: 0;
	}
}

//  Extracted as `@mixin list-unstyled` for libsass compatibility
.list-unstyled {
	@include list-unstyled;
}

// Inline turns list items into inline-block
.list-inline {
	@include list-unstyled;
	margin-left: -5px;

	& > li {
		display: inline-block;
		padding-right: 5px;
		padding-left: 5px;
	}
}

.o-list {
	display: flex;
	margin: 0;
	padding: 0;
	font-size: 1rem;
	color: $gray;
	list-style: none;

	&__item {
		flex: 0 0 auto;

		&--has-border-bottom {
			border-bottom: 1px solid $gray-lighter;

			&:last-child {
				border-bottom: 0;
			}
		}
	}

	&--dot {
		font-family: $font-family-base;
		color: $gray-base;

		& li + li::before {
			position: relative;
			padding: 0 6px;
			min-width: 1px;
			flex: 0 0 auto;
			content: ' · ';
		}
	}

	&--col {
		flex-direction: column;
	}

	&__tl {
		@include ellipsis();
		font-size: 1.4em;
		font-weight: $bold;
		color: $gray-darker;
	}
}

.metadata {
	@include o-list(dot);

	&__item {
		@include o-list__item(dot);
	}
}

.list-group {
	@include o-list(col);
	@include btn-tiles();

	$this:&;

	&__item {
		@include o-list__item(has-border-bottom);
	}

	& a#{$this}__tl {
		display: block;
		width: 100%;
	}
}
