
.loader-placeholder {
	display: inline-block;
	vertical-align: middle;
	background-color: $gray-lighter !important;
}

.loader-text {
	display: block;
	height: 14px;
	border-radius: $border-radius-base;

	&.is-inline {
		display: inline-block;
		min-width: 46%;
		max-width: 60%;
	}

	&.is-12 {
		height: 12px;
	}

	&.is-14 {
		height: 14px;
	}

	&.is-15 {
		height: 15px;
	}

	&.is-18 {
		height: 18px;
	}

	&.is-32 {
		height: 32px;
	}
}

.loader-square {
	border-radius: $border-radius-small; // border-radius: 2px;
}

.loader-circle {
	width: 36px;
}

.loader-placeholder-wrap {
	position: relative;
	overflow: hidden;
}

.gradient-loader {
	position: absolute;
	top: 0;
	left: -180px;
	z-index: 1;
	width: 180px;
	height: 60px;
	background: linear-gradient(to right, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.2) 100%);
	animation: 1s move-left infinite linear;
	animation-fill-mode: forwards;
}
