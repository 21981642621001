/**
 *  Mixin used to print values for tags h1-h6 and class .fs-h1-.fs-h6,
 *  Can be used as @include to custom class with optional argument h1-h6
 */
@mixin headings($size:'') {
	@if $size != '' {
		$map: map-get($headings, $size);
		@include print-list($map);
	}
	@each $heading, $values in $headings {
		#{$heading},
		.fs-#{$heading} {
			@include print-list($values);
		}
	}
}
