.crd-tl {
	display: inline-block;
	margin-bottom: 6px;
	font-size: 1.4rem;
	font-weight: $medium;
	line-height: 18px;
}

.crd {
	position: relative;
	margin-bottom: 24px;
	padding: 18px;
	border-radius: $border-radius-base;
	background: $white;

	& img {
		max-width: 100%;
	}

	&__hd {
		position: relative;
	}

	&__hdg {
		font-size: 1.4rem;
		font-weight: $medium;
		color: $gray-darker;
		cursor: pointer;
	}

	// Post heading on Post show page
	&__post-hdg {
		font-size: 3.6rem;
		color: $gray-darker;
	}

	// Icons and Initials in card
	&__ico {
		display: flex;
		align-items: center;
		justify-content: center;

		&--md {
			height: 48px;
			font-size: 4.8rem;
			line-height: 48px;
		}

		&--sm {
			height: 36px;
			font-size: 36px;
			line-height: 36px;
		}

		&--list {
			height: 100%;
			min-height: 60px;
		}

		& i {
			color: $gray-dark;
		}
	}

	&__info--icon {
		@include angie-spacing(margin, right, spacing-12);
	}

	//## LIST CARD

	&--list {
		@include default-animation(transform);
		@include crd-effect(normal);
		display: flex;
		margin-bottom: 12px;
		padding: 12px 18px;
		min-height: 60px;

		&:hover {
			@include crd-effect(hover);
			z-index: 3;
			transform: translateY(-2px);
		}

		& .crd__img {
			margin: -11px 18px -11px -17px;
			width: 60px;
			min-height: 60px;
			flex: 0 0 auto;
			border-radius: 4px 0 0 4px;
			background-position: center;
			background-size: cover;

			&.crd--crse {
				width: 90px;
			}
		}

		// Header element for List card
		& > .crd__hd {
			align-self: center;
			flex: 0 0 auto;
		}

		// Header modifier  for images on List card

		/**  Class .crd__hd--stretch represent wher can go (images, initial...), usage on
		 *  Topic list view
		 *  Post list view
		 */

		/**  Class .crd__hd--img-ty2 represent type 2(rectangle images), usage on
		*  Course image
		*  Catalog image
		*/

		& > .crd__hd--stretch,
		& > .crd__hd--img-ty2 {
			display: flex;
			margin: -11px 18px -11px -17px;
			width: 60px;
			min-height: 60px;
			align-self: stretch;
			border-radius: 4px 0 0 4px;
			background-position: center;
			background-size: cover;
		}

		& > .crd__hd--stretch {
			overflow: hidden;
		}

		& > .crd__hd--img-ty2 {
			width: 90px;
		}

		& > .crd__hd--flex {
			margin-right: 18px;
			width: auto;
			align-self: stretch;
			flex: 0 0 auto;
		}

		& > .crd__hd--r-sm {
			margin-right: 18px;
		}

		// Body element for List card
		& .crd__bd {
			display: flex;
			min-width: 1px; // Hack for ellipsis in anchor tag
			align-self: center;
			flex: 1 1 auto;
		}

		& .crd__cnt {
			position: relative;
			min-width: 1px; // Hack for ellipsis in anchor tag
			align-self: center;
			flex: 1 1 auto;
		}

		& .crd__act {
			padding: 0 12px;
			align-self: center;
			flex: 0 0 auto;

			&:last-child {
				padding-right: 0;
			}
		}

		// 3dot menu on List card
		&.crd--3dot {
			padding: 12px 44px 12px 18px;

			& .dots-ddm {
				@extend %abs-cent-rgh-el;
				opacity: 1;
			}

			// not perfect selector but to avoid 4 level nesting i defined it as separate
			& .dots-ddm__btn {
				opacity: 0;

				&:focus {
					opacity: 1;
				}
			}

			&:hover {

				.dots-ddm__btn {
					opacity: 1;
				}
			}

			// Archive post document-overlay
			&.crd--archive::after {
				padding-left: 18px;
				justify-content: flex-start;
			}
		}

		// Modifier when card has 3dot menu and card footer in the same time.
		&.crd--list-has-ft {

			& > .dots-ddm {
				top: 28px;
			}
		}
	}

	//## LIST CARD WITH FOOTER

	&--list-has-ft {
		flex-wrap: wrap;

		& .crd__ft {
			margin: 12px -18px -12px -18px;
			padding: 4px 18px;
			flex: 1 1 100%;
			font-size: 0.75rem;
			border-radius: 0 0 4px 4px;
			background: $gray-lighter;
		}
	}

	//## GRID CARD

	&--grid {
		@include crd-effect(normal);
		@include default-animation(transform);
		display: flex;
		flex-direction: column;

		&:hover {
			@include crd-effect(hover);
			z-index: 3;
			transform: translate3d(0, -2px, 0);
		}
	}

	//## CENTERED CARD

	&--centered {
		padding: 36px;
		height: 300px;
		justify-content: center;

		& .crd__bd {
			display: flex;
			text-align: center;
		}

		// Headding  on card type 3
		& .crd__hdg {
			display: inline-block;
			margin-top: 18px;
			margin-bottom: 12px;
			font-size: 1.6rem;
		}
	}

	//## POST CARD

	&--post {

		border: 1px solid $gray-pale;
		//Element for header
		& .crd__hd {
			position: relative;
			margin: -18px -18px 12px;
			height: 195px;
			border-radius: 4px 4px 0 0;

			&--gray {
				background-color: $gray-lighter;
			}

			&--primary {
				background-color: $semantic-progress-pale;
			}
		}

		& .crd__figure {
			height: 100%;
			text-align: center;
		}

		//Element for header img
		& .crd__img {
			position: relative;
			height: 100%;
			cursor: pointer;
			border-radius: 4px 4px 0 0;
		}

		& .crd__img--loading {
			position: absolute;
			top: -2px;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			width: 48px;
		}

		//Element for body
		& .crd__cnt {
			position: absolute;
			bottom: 0;
			left: 0;
			padding: 12px 18px 0 18px;
			width: 100%;
			height: auto;
			background: $white;

			// Modifier for post cards who hasn't post cover image
			&--no-img {
				height: 83%;
			}

			& > a,
			& > .link,
			& > p {
				word-wrap: break-word;
			}
		}
	}

	//## POST CARD WITH PRIMARY COLORS
	&--post-primary {
		border: 2px solid $semantic-progress-pale-solid;
	}

	// Modifier for list-header
	&--list-hd {
		margin-bottom: 12px;
		padding: 12px 18px;
	}

	// Modifier for dropdown menu from 3dot
	&--3dot {

		// Modifier when card has 3dot menu and card footer in the same time.
		& .crd__ft {
			margin: 12px -44px -12px -18px;
		}
	}

	// Modifier for segment preview card
	&--segments-preview {
		margin-top: -24px;

		& .card > section {
			margin-bottom: 0;
			padding: 0;
		}
	}

	// Dropdown on card
	& .dropdown-menu {
		padding: 0;
		overflow: hidden;
		font-size: 1rem;

		& li a {
			padding: 12px 18px;
			font-size: 1.4em;

			&:hover {
				font-weight: $regular;
				text-decoration: none;
			}
		}

		& .dropdown-menu__icon {
			margin-right: 12px;
		}
	}

	// Effect for archive card (use on Forums)
	&--archive {
		overflow: hidden;

		&::after {
			@include transition (
				opacity 0.2s cubic-bezier(0.4,0,0.2,1),
				transform 0.2s cubic-bezier(0.4,0,0.2,1),
				z-index 0.2s cubic-bezier(0.4,0,0.2,1)
			);
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 3;
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: $font-awesome-regular;
			font-size: 3.2rem;
			content: '';
			opacity: 1;
			background: rgba(255, 255, 255, 0.7);
			transform: scale(1);
		}

		&:hover {
			overflow: visible;
		}

		&:hover::after {
			@include transition (
				opacity 0.24s cubic-bezier(0.4,0,0.2,1) ,
				transform 0.24s cubic-bezier(0.4,0,0.2,1),
				z-index 0.24s cubic-bezier(0.4,0,0.2,1)
			);
			z-index: -3;
			opacity: 0;
			transform: scale(0.8, 0.8);
		}
	}

	// Modifier for cards which will disable effect (translateY) on hover
	&--no-effect:hover {
		transform: translateY(0);
	}

	// Modifier for cards which will disable box-shadow on hover
	&--no-shadow:hover {
		@include crd-effect(none);
	}

	// Modifier for cards different padding
	&--md {
		padding: 36px;
	}

	&--lg {
		padding: 48px;
	}

	// Modifiet for globa search results
	&--gs-results {
		margin: 0 auto;
		max-width: 792px;
	}

	// Modifiet for crd on content details page
	&--border {
		@include angie-spacing(margin, bottom, spacing-00);
		@include angie-spacing(padding, x, spacing-24);
		@include angie-spacing(padding, y, spacing-16);
		border: 1px solid $gray-pale;
		border-radius: $border-radius-extra-large;
	}
}

// Rail of the card could be use on various types of cards
.crd-rail {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	display: flex;
	padding: 0 18px;
	width: 100%;
	height: 38px;
	align-items: center;
	border-radius: 4px 4px 0 0;
	background: transparent;

	/* stylelint-disable */
	& a,
	& a:hover,
	& a:focus {
		font-size: 1.2rem;
		font-weight: $medium;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}

		& > a {
			margin-left: 0;
			color: $gray-darker;
		}
	}
	/* stylelint-enable */

	&--dark {
		color: $white;
		background: rgba(0, 0, 0, 0.6);
	}

	&__cnt {
		position: relative;
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: flex-end;

		&--has-subset {
			padding-left: 24px;
		}
	}
}

.crd-dashboard {
	@include side-crd();
	@include angie-spacing(padding, x, spacing-16);
	@include angie-spacing(padding, top, spacing-16);

	margin-bottom: 0;
	width: 100%;
	border: 1px solid $gray-lighter;
	border-radius: $border-radius-large;
}
