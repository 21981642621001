.btn {
	@include btn();
	@include btn-ico();
	@include btn-block();
	@include btn-size();

	// Modifier for primary navigation and actions navigations
	&--primary-nav,
	&--actions-nav {
		@include icon-size('small');
		padding-top: 3px;
		padding-bottom: 3px;
	}

	&--block {
		@include btn-style($btn-block);
		@include transition(color);
		@include default-animation(color);
		margin-bottom: 12px;
		padding: 10px 18px;
	}

	// &--default -> Styles for default buttons are in Theme.scss

	// &--theme-lighter -> Button style defined in lu-theme.scss.erb

	&--tertiary {
		@include btn-style($btn-tertiary);
	}

	&--success {
		@include btn-style($btn-success);
	}

	&--success-state {
		@include btn-style($btn-success-state);
	}

	&--danger {
		@include btn-style($btn-danger);
	}

	&--danger-state {
		@include btn-style($btn-danger-state);
	}

	&--white {
		@include btn-style($btn-white);
	}

	&--ghost {
		@include btn-style($btn-ghost);
	}

	&--ghost-theme {
		@include btn-style($btn-ghost-theme);
	}

	&[disabled] {
		cursor: $cursor-disabled;
		opacity: $disabled-opacity;
	}

	&--hidden {
		display: none;
	}
}

.dots-ddm {
	position: relative;
	width: 3.2em;
	height: 3.2em;
	font-size: 1rem;
	line-height: 3.2em;
	text-align: center;
	opacity: 0;

	&--sm {
		width: 2.4em;
		height: 2.4em;
		line-height: 2.4em;
		text-align: center;
	}

	&--abs {
		@extend %abs-cent-rgh-el;
	}

	&--ty4 {
		margin-right: -10px;
	}

	&__btn {
		@extend %ripple-effect;
		position: relative;
		z-index: 1;
		padding: 0;
		width: 100%;
		height: 100%;
		font-size: 1.4rem;
		border: 0;
		background: transparent;

		&:focus {
			opacity: 1;
		}

		&:hover::before {
			border-radius: $border-radius-circle;
			background: $gray-lighter;
			transform: scale(1);
		}
	}
}

a.btn {

	&.disabled,
	fieldset[disabled] & {
		pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
	}

	&--block {
		text-align: left;
	}
}

// Specificity overrides
input[type='submit'],
input[type='reset'],
input[type='button'] {

	&.btn--block {
		width: 100%;
	}
}

//@@ TABLETS BREAK POINT MAX-WIDTH:767px

@media (max-width: 767px) {

	.btn-xs-block {
		display: block;
		width: 100%;
	}
}
