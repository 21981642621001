// Scroll Down animation
.bounce-arrow {
	-webkit-animation: bounce-arrow 2s infinite;
	-moz-animation: bounce-arrow 2s infinite;
	animation: bounce-arrow 2s infinite;
}

@keyframes bounce-arrow {

	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0);
	}

	40% {
		transform: translateY(-10px);
	}

	60% {
		transform: translateY(-5px);
	}
}

@keyframes border-animation {

	0% {
		opacity: 1;
		border-width: 2em;
	}

	40% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		border-width: 0;
		transform: scale(1.2);
	}
}
