


//## GRID SYSTEM


// Centered container element
@use "sass:math";

@mixin container-fixed($gutter: $grid-gutter-width) {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-left:  floor(($gutter * 0.5));
	padding-right: ceil(($gutter * 0.5));

	@media (min-width: $lu-screen-s-min) {
		padding-right: $gutter;
		padding-left: $gutter;
	}
}

// Creates a wrapper for a series of columns
@mixin make-row($gutter: $grid-gutter-width) {
	display: flex;
	flex-wrap: wrap;
	margin-right: math.div($grid-gutter-width, -2);
	margin-left: math.div($grid-gutter-width, -2);
}

// Creates a wrapper for a series of columns
@mixin make-wrap($gutter: $grid-gutter-width) {
	display: flex;
	flex-wrap: wrap;
}

// Creates hidden element for different breakpoints
@mixin make-hidden-el ($breakpoint) {
	@if($breakpoint == xs) {
		@media (max-width: $lu-screen-xs) {
			display: none;
		}
	}

	@if($breakpoint == sm) {
		@media (max-width: $lu-screen-s) {
			display: none;
		}
	}

	@if($breakpoint == md) {
		@media (max-width: $lu-screen-m) {
			display: none;
		}
	}

	@if($breakpoint == lg) {
		@media (max-width: $lu-screen-l) {
			display: none;
		}
	}

}

// Make grid columns
@mixin make-grid-columns() {

	%grid-column {
		position: relative;
		width: 100%;
		min-height: 1px; // Prevent columns from collapsing when empty
		padding-right: ($grid-gutter-width * 0.5);
		padding-left: ($grid-gutter-width * 0.5);
	}

	.col {
		@extend %grid-column;
	}

}

// Calculation for grid columns
@mixin calc-grid-column($index, $class, $type) {
	@if ($type == width) and ($index > 0) {
		.#{$class}-#{$index} {
			flex: 0 0 percentage(math.div($index, $grid-columns));
			max-width: percentage(math.div($index, $grid-columns));
		}
	}

}


@mixin loop-grid-columns($columns, $class, $type) {
	@for $i from 0 through $columns {
		@include calc-grid-column($i, $class, $type);
	}
}



// Create grid for specific class
@mixin make-grid($class) {
	@include loop-grid-columns($grid-columns, $class, width);
}
