@import 'variables';
@import 'mixins';

.blank-state {
	margin: 0 auto;
	padding: 24px 0;
	max-width: 660px;
	text-align: center;

	// md represent just padding top and bottom of blank state in future could be add e.g.(lg,sm,xs)
	&.md {
		padding: 64px 0;
	}

	// Modifier for dashed border above blank state
	&--dashed {
		padding: 24px;
		text-align: center;
		border: 2px dashed $gray-light;
		border-radius: 2px;
	}

	// Modifier for full width of blank state
	&--fluid {
		max-width: 100%;
	}
}

// Blank state image
.blank-state-img {
	display: block;
	margin: 0 auto;
	width: auto;
	max-height: 140px;
}

// Default loading staate in blank state image
.load-st {
	position: relative;
	margin: 0 auto;
	width: 254px;
	height: 140px;
	overflow: hidden;
}

// Title of blank state
.blank-state-title {
	margin: 24px 0 6px 0;
	font-size: 1.5rem;
	font-weight: $bold;
	color: $gray-darker;
}

// Description of blank state below of title
.blank-state-text {
	margin-bottom: 24px;
	font-size: 1.2rem;
	font-weight: $regular;
	color: $gray;
}

// Container for step animation in blank state used for delay job
.animo-cntr {
	position: relative;
	margin: 0 auto;
	width: 254px;
	height: 140px;
	overflow: hidden;
}

// Button of blank state
.blank-state-btn {
	margin-top: 24px;

	& > i {
		margin-right: 6px;
	}
}

// This is on left side add first course module Should be refactor to blank-state
.empty-content {
	padding: 18px 0;
	text-align: center;

	& i {
		font-size: 3.6rem;
		color: $gray-light;
	}

	& p {
		margin-top: 12px;
	}
}

//## IMAGE STATE

.img-state {
	position: relative;

	& .img-responsive {
		margin: 0 auto;
	}

	& .img-full-width {
		width: 100%;
	}

	&__rail {

		&--dark {
			position: absolute;
			bottom: 0;
			left: 0;
			padding: 6px 12px;
			width: 100%;
			text-align: center;
			background: rgba(0, 0, 0, 0.7);
		}
	}
}
