// Material global - temp to unblock table related US development
@use '@angular/material' as mat;
@use '@angular/cdk' as cdk;
// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';

//@import "node_modules/@angular/material/core/core";

@mixin mat-global {

	// Define your application's custom theme.
	$primary: mat.define-palette(mat.$deep-purple-palette);
	$accent: mat.define-palette(mat.$deep-purple-palette);
	//NOTE: figure something out here
	$theme: mat.define-light-theme(
		(
			color: (
				primary: $primary,
				accent: $accent,
				foreground: $primary,
				background: $primary
			)
		)
	);

	// moving pieces
	@include mat.core();
	@include mat.ripple();
	@include mat.core-theme($theme);
	@include mat.table-theme($theme);
	@include mat.paginator-theme($theme);
	@include mat.form-field-theme($theme);
	@include mat.select-theme($theme);
	@include mat.button-theme($theme);
	@include mat.progress-bar-theme($theme);
	@include cdk.overlay();

	.mat-elevation-z8 {
		@include mat.elevation(8);
	}
	// table pieces (copy-pasted from their demo)
	table {
		width: 100%;
	}
}
