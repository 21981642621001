@import "../../../../../common/styles/common-vars";


//## VARIABLES

$bootstrap-sass-asset-helper: false !default;

//## Layout admin navigation width
$collapsed: 66px;
$expanded: 240px;

//##  COLORS
// See client/common/styles/_common-vars.scss
// See client/common/styles/_design-tokens.scss

//##  SCAFFOLDING

// Settings for some of the most global styles.

// Background color for `<body>`.
$body-bg: $brand-primary !default;
// Global text color on `<body>`.
$text-color: $gray-dark !default;

// Global textual link color.
$link-color: $brand-primary !default;
// Link hover color set via `darken()` function.
$link-hover-color: $brand-primary !default;
// Link hover decoration.
$link-hover-decoration: underline !default;



//##  TYPOGRAPHY
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Inter var', sans-serif !default;
$font-awesome-regular: "Font Awesome 5 Regular" !default;
$font-family-signature: 'Sunrise' !default;

// Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;
$font-size-base: 16px !default;
$font-size-small: ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1: ceil(($font-size-base * 1.602)) !default; // ~26px
$font-size-h2: ceil(($font-size-base * 1.424)) !default; // ~23px
$font-size-h3: ceil(($font-size-base * 1.25)) !default; // ~20px
$font-size-h4: ceil(($font-size-base * 1.125)) !default; // ~18px
$font-size-h5: $font-size-base !default;
$font-size-h6: ceil(($font-size-base * 0.875)) !default; // ~14px

$font-size-p: ceil(($font-size-base * 0.875)) !default; // ~14px
$font-size-p-lg: $font-size-base !default; // ~16px

$font-size-label: ceil(($font-size-base * 0.875)) !default; // ~14px

// font-weight
$light: 350 !default;
$regular: 450 !default;
$medium: 550 !default;
$bold: 750 !default;

// Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.428571429 !default; // 20/14

// Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)) !default; // ~20px

// By default, this inherits from the `<body>`.
$headings-font-family: inherit !default;
$headings-font-weight: $bold ;
$headings-line-height: 1.1 !default;
$headings-color: inherit !default;



//##  COMPONENTS

// Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).
$padding-base-vertical: 7px !default;
$padding-large-vertical: 10px !default;
$padding-small-vertical: 5px !default;
$line-height-large: 1.3333333 !default; // extra decimals for Win 8.1 Chrome
$line-height-small: 1.5 !default;
$border-radius-base: 4px !default;
$border-radius-large: 6px !default;
$border-radius-extra-large: 8px !default;
$border-radius-small: 2px !default;
$border-radius-circle: 50% !default;

$divider-color: $gray-lighter;
$divider-margin-y: 8px;

// Global color for active items (e.g., navs or dropdowns).
$component-active-color: $white !default;

// Global background color for active items (e.g., navs or dropdowns).
$component-active-bg: $brand-primary !default;

// Width of the `border` for generating carets that indicator dropdowns.
$caret-width-base: 6px !default;



//##  BUTTONS

// For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight: $bold !default;
$btn-default-color: $gray-dark !default;
$btn-default-bg: $gray-lighter !default;
$btn-default-border: $gray-lighter !default;
$btn-primary-color: $white !default;
$btn-primary-bg: $brand-primary !default;
$btn-primary-border: $brand-primary !default;
$btn-success-color: $white !default;
$btn-success-bg: $brand-success !default;
$btn-success-border: $brand-success !default;
$btn-info-color: $white !default;
$btn-info-bg: $brand-info !default;
$btn-info-border: $brand-info !default;
$btn-warning-color: $white !default;
$btn-warning-bg: $brand-warning !default;
$btn-warning-border: $brand-warning !default;
$btn-danger-color: $white !default;
$btn-danger-bg: $brand-danger !default;
$btn-danger-border: $brand-danger !default;
$btn-link-disabled-color: $gray-light !default;



//##  FORMS

// `<input>` background color
$input-bg: $white !default;
// `<input disabled>` background color
$input-bg-disabled: $gray-lighter !default;

// Text color for `<input>`s
// $input-color: $gray !default;

// `<input>` border color
$input-border: $gray-light !default;

// Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius: $border-radius-base !default;

// Placeholder text color
$input-color-placeholder: #999999 !default;

// Default `.form-control` height
$input-height-base: ($line-height-computed + ($padding-base-vertical * 2) + 2) !default;

// Disabled cursor for form controls and buttons.
$cursor-disabled: not-allowed !default;

// Disabled opacity
$disabled-opacity: 0.5;

//##  FORMS

$label-color: $gray-darker;
$input-bg: $white !default;
$input-bg-disabled: $gray-lighter !default;
$input-color: $gray-darker !default;
$input-border: $gray-light !default;
$input-border-radius: $border-radius-base !default;
$input-color-placeholder: $gray !default;
$input-border-hover: $gray !default;
$input-border-error: $brand-danger !default;


// `.form-group` margin
$form-group-margin-bottom: 12px !default;
$legend-color: $gray-dark !default;
$legend-border-color: #e5e5e5 !default;

// Disabled cursor for form controls and buttons.
$cursor-disabled: not-allowed !default;


//##  DROPDOWNS

// Dropdown menu container and contents.

// Background for the dropdown menu.
$dropdown-bg: $white !default;

// Dropdown menu `border-color`.
$dropdown-border: rgba(0,0,0,0.15) !default;

// Divider color for between dropdown items.
$dropdown-divider-bg: #e5e5e5 !default;

// Dropdown link text color.
$dropdown-link-color: $gray-dark !default;

// Hover background for dropdown links.
$dropdown-link-hover-bg: #f5f5f5 !default;

// Active dropdown menu item text color.
$dropdown-link-active-color: $component-active-color !default;

// Active dropdown menu item background color.
$dropdown-link-active-bg: $component-active-bg !default;

// Disabled dropdown menu item background color.
$dropdown-link-disabled-color: $gray-light !default;

// Text color for headers within dropdown menus.
$dropdown-header-color: $gray-light !default;

$dropdown-shadow: 0px 6px 18px 0px rgba(0,0,0,0.18) !default;

$dropdown-link-hover-color: $gray-dark !default;
$dropdown-link-hover-bg: $gray-lighter !default;


//## Z-INDEX MASTER LIST

// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
// Note: These variables are not generated into the Customizer.

$zindex-dropdown: 1000 !default;
$zindex-tooltip: 1070 !default;


//## BREAKPOINTS

// Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Phone
$lu-screen-xs:            480px !default;
$lu-screen-xs-min:        $lu-screen-xs !default;

// Small Tablets
$lu-screen-s:             840px !default;
$lu-screen-s-min:         $lu-screen-s !default;

// Tablets
$lu-screen-m:             1024px !default;
$lu-screen-m-min:         $lu-screen-m !default;

// Bigger tablets and small screens
$lu-screen-l:             1280px !default;
$lu-screen-l-min:         $lu-screen-l !default;

// Screens
/**
 *  NOTE  variables for xl represent  lg and it will  be render on css like lg
 */
$lu-screen-xl:            1600px !default;
$lu-screen-xl-min:        $lu-screen-xl !default;

// So media queries don't overlap when required, provide a maximum
$lu-screen-xxs-max:       ($lu-screen-xs-min - 0.02) !default; // width: 479.98px;
$lu-screen-xs-max:        ($lu-screen-s-min - 0.02) !default;  // max-width: 839.98px;
$lu-screen-s-max:         ($lu-screen-m-min - 0.02) !default;  // max-width: 1023.98px;
$lu-screen-m-max:         ($lu-screen-l-min - 0.02) !default;  // max-width: 1279.98px;
$lu-screen-l-max:         ($lu-screen-xl-min - 0.02) !default; // max-width: 1599.98px;

//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns: 12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 24px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $lu-screen-s-min !default;


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// 575 / tablet
$lu-container-phone:       (456px + $grid-gutter-width) !default;
//** For `$lu-screen-sm-min` and up.
$lu-containern-xs:         $lu-container-phone !default;


// 576/ tablet
$lu-container-small-tablet:       (792px + $grid-gutter-width) !default;
//** For `$lu-screen-sm-min` and up.
$lu-containern-s:                 $lu-container-small-tablet !default;

// Medium screen / desktop
$lu-container-tablet:             (792px + $grid-gutter-width) !default;
//** For `$lu-screen-md-min` and up.
$lu-containern-m:                 $lu-container-tablet !default;

// Large tablets and smaller laptops
$lu-container-large-tablet:       (1200px + $grid-gutter-width) !default;
//** For `$lu-screen-lg-min` and up.
$lu-containern-l:                 $lu-container-large-tablet !default;

// Large tablets and smaller laptops.
$lu-container-regular-screens:    (1576px + $grid-gutter-width) !default;
//** For `$lu-screen-lg-min` and up.
$lu-containern-xl:                $lu-container-regular-screens !default;

// lu-container--m - custom container for course description page
$lu-container-cd:                 (976px + $grid-gutter-width) !default;
$lu-containern-cd:                $lu-container-cd !default;


//##  NAVBAR

// Basics of a navbar
$navbar-height: 44px !default;
$navbar-default-bg: $white !default;

// Navbar toggle
$navbar-default-toggle-hover-bg: #dddddd !default;
$navbar-default-toggle-icon-bar-bg: #888888 !default;
$navbar-default-toggle-border-color: #dddddd !default;



//##  FORM STATES AND ALERTS

// Define colors for form feedback states and, by default, alerts.
$state-success-bg: $brand-success !default;
$state-info-bg: $brand-info !default;
$state-warning-bg: $brand-warning !default;
$state-danger-bg: $brand-danger !default;




//##  TOOLTIPS

// Tooltip max width
$tooltip-max-width: 260px !default;

// Tooltip text color
$tooltip-color: $white !default;

// Tooltip background color
$tooltip-bg: $gray-darker !default;
$tooltip-opacity: 1 !default;

// Tooltip arrow width
$tooltip-arrow-width: 20px !default;

// Tooltip arrow height
$tooltip-arrow-height: 10px !default;

// Tooltip padding y
$tooltip-padding-y: 5px !default;

// Tooltip padding x
$tooltip-padding-x: 12px !default;

// Tooltip arrow color
$tooltip-arrow-color: $gray-darker !default;



//##  LABELS

// Default label text color
$label-color: $white !default;



//##  MODALS

// Modal width
$modal-sm: 300px !default;
$modal-default: 520px !default;

$conf-modal-lg: $modal-default;


//##  ALERTS

// Define alert colors, border radius, and padding.
$alert-border-radius: $border-radius-base !default;
$alert-link-font-weight: $bold;



//##  THUMBNAILS

// Padding around the thumbnail image
$thumbnail-padding: 4px !default;

// Thumbnail background color
$thumbnail-bg: $body-bg !default;

// Thumbnail border color
$thumbnail-border: #dddddd !default;

// Thumbnail border radius
$thumbnail-border-radius: $border-radius-base !default;



//##  BADGES

// Badge background color in active nav link
$badge-font-weight: $bold;
$badge-line-height: 1 !default;



//##  BREADCRUMBS

$breadcrumb-padding-vertical: 8px !default;
$breadcrumb-padding-horizontal: 15px !default;

// Breadcrumb background color
$breadcrumb-bg: #f5f5f5 !default;

// Breadcrumb text color
$breadcrumb-color: $gray-light !default;

// Text color of current page in the breadcrumb
$breadcrumb-active-color: $gray-light !default;

// Textual separator for between breadcrumb elements
$breadcrumb-separator: '/' !default;


//##  CAROUSEL

$carousel-text-shadow: 0 1px 2px rgba(0,0,0,0.6) !default;
$carousel-control-color: $white !default;
$carousel-control-width: 15% !default;
$carousel-control-opacity: 0.5 !default;
$carousel-control-font-size: 2.0rem !default;
$carousel-indicator-active-bg: $white !default;
$carousel-indicator-border-color: $white !default;
$carousel-caption-color: $white !default;


//##  CLOSE

$close-font-weight: $bold;
$close-color: $black;
$close-text-shadow: 0 1px 0 $white !default;


//##  CODE

$code-color: #c7254e !default;
$code-bg: #f9f2f4 !default;
$kbd-color: $white !default;
$kbd-bg: $gray-darker !default;
$pre-bg: #f5f5f5 !default;
$pre-color: $gray-dark !default;
$pre-border-color: $gray-light !default;
$pre-scrollable-max-height: 340px !default;


//##  TYPE

// Horizontal offset for forms and lists.
$component-offset-horizontal: 180px !default;

// Text muted color
$text-muted: $gray-light !default;

// Abbreviations and acronyms border color
$abbr-border-color: $gray-light !default;

// Headings small color
$headings-small-color: $gray-light !default;

// Blockquote small color
$blockquote-small-color: $gray-light !default;

// Blockquote font size
$blockquote-font-size: ($font-size-base * 1.25) !default;

// Blockquote border color
$blockquote-border-color: $gray-lighter !default;

// Page header border color
$page-header-border-color: $gray-lighter !default;

// Width of horizontal description list titles
$dl-horizontal-offset: $component-offset-horizontal !default;

// Point at which .dl-horizontal becomes horizontal
$dl-horizontal-breakpoint: $grid-float-breakpoint !default;

// Horizontal line color.
$hr-border: $gray-pale !default;

// Area allowed for max height content inside main header
$mainheader-height: 36px;




//## SHADOW

$shadow1: 0 5px 8px -3px rgba(0,0,0,0.40);
$shadow2: 0 2px 5px -1px rgba(0,0,0,0.30);

// .crd shadow
$crd-shadow: 0 1px 4px rgba(0,0,0,.16);

// another card shadow often used
$crd-shadow-2: 0 2px 10px rgba(0, 0, 0, 0.12);

//Variables for widget header height, used for calc() height of widget body
$widget__hd: 48px;

//Drawer width
$drawer-width-default: 480px;
$drawer-width-large: 640px;



