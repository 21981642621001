
// .modal-open			- body class for killing the scroll
// .modal					 - container to scroll within
// .modal-dialog		- positioning shell for the actual modal

// Kill the scroll on the body
.modal-open {
	overflow: hidden;
}

// Container that the modal scrolls within
.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9999;
	display: none;
	overflow: hidden;
	-webkit-overflow-scrolling: touch;
	// Prevent Chrome on Windows from adding a focus outline. For details, see
	// https://github.com/twbs/bootstrap/pull/10951.
	outline: 0;

	// When fading in the modal, animate it to slide down
	&.fade .modal-dialog {
		// opacity: 1;
		transition: transform 0.3s ease-out;
		transform: translate(0, 0%) translate3d(0, 100px, 50px);
		will-change: transform;
	}

	&.in .modal-dialog {
		transform: translate(0, 0);
	}
}

.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
}

// Modal background
.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9999;
	background-color: $black;

	// Fade for backdrop
	&.fade {
		opacity: 0;
	}

	&.in {
		opacity: 0.5;
	}
}

//## DEFAULT BOOTSTRAP MODAL
.modal.in .modal-dialog,
.modal.in-add .modal-dialog {
	opacity: 1;
	transform: translate3d(0, 0, 0);
}
