
//## GRID SYSTEM

// Container widths
.lu-container {
	@include container-fixed;
	max-width: $lu-containern-l;

	// max-width: 952px
	&--m {
		max-width: $lu-containern-cd;
	}

	// max-width: 1600px
	&--xl {
		max-width: $lu-containern-xl;
	}
}

// Fluid container
.lu-container-fluid {
	@include container-fixed;
}

// Row include negative margine and flex-wrap for columns
.lu-row {
	@include make-row;

	&--vcenter {
		align-items: center;
	}

	&--hcenter {
		justify-content: center;
	}

	&--hend {
		justify-content: flex-end;
	}
}
// Lu-wrap include flex-wrap for columns
.lu-wrap {
	@include make-wrap;
}

// Modifier for centered col
.col--center {
	margin: 0 auto;
}
// Small and large grid columns
@include make-grid-columns;

// Extra small grid (xs)
@include make-grid(xs);

// Small grid (sm)
@media (min-width: $lu-screen-s-min) {
	@include make-grid(sm);
}

// Medium grid (md)
@media (min-width: $lu-screen-m-min) {
	@include make-grid(md);
}

// Large grid (lg)
@media (min-width: $lu-screen-l-min) {
	@include make-grid(lg);
}

// Extra large grid (xl) We don't use extra large at the moment

/* @media (min-width: $lu-screen-xl-min) {
	@include make-grid(xl);
} */
