/* stylelint-disable no-descending-specificity */
// Copied from bootstrap 5
// Notes on the classes:
//
// 1. .carousel.pointer-event should ideally be pan-y (to allow for users to scroll vertically)
//    even when their scroll action started on a carousel, but for compatibility (with Firefox)
//    we're preventing all actions instead
// 2. The .carousel-item-start and .carousel-item-end is used to indicate where
//    the active slide is heading.
// 3. .active.carousel-item is the current slide.
// 4. .active.carousel-item-start and .active.carousel-item-end is the current
//    slide in its in-transition state. Only one of these occurs at a time.
// 5. .carousel-item-next.carousel-item-start and .carousel-item-prev.carousel-item-end
//    is the upcoming slide in transition.
// sass-lint:disable

// Carousel

// scss-docs-start carousel-variables
$carousel-control-color:             $white !default;
$carousel-control-width:             15% !default;
$carousel-control-opacity:           0.5 !default;
$carousel-control-hover-opacity:     0.9 !default;
$carousel-control-transition:        opacity 0.15s ease !default;
$carousel-indicator-width:           30px !default;
$carousel-indicator-height:          3px !default;
$carousel-indicator-hit-area-height: 10px !default;
$carousel-indicator-spacer:          3px !default;
$carousel-indicator-opacity:         0.5 !default;
$carousel-indicator-active-bg:       $white !default;
$carousel-indicator-active-opacity:  1 !default;
$carousel-indicator-transition:      opacity 0.6s ease !default;
$carousel-caption-width:             70% !default;
$carousel-caption-color:             $white !default;
$carousel-caption-padding-y:         1.25rem !default;
$carousel-caption-spacer:            1.25rem !default;
$carousel-control-icon-width:        2rem !default;
$carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !default;
$carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>") !default;
$carousel-transition-duration:       0.6s !default;
$carousel-transition:                transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)
$carousel-dark-indicator-active-bg:  $black !default;
$carousel-dark-caption-color:        $black !default;
$carousel-dark-control-icon-filter:  invert(1) grayscale(100) !default;
$enable-gradients:            false !default;

.carousel {
	position: relative;
}

.carousel.pointer-event {
	touch-action: pan-y;
}

.carousel-inner {
	@include clearfix();
	position: relative;
	width: 100%;
	overflow: hidden;
}

.carousel-item {
	@include transition($carousel-transition);
	position: relative;
	display: none;
	float: left;
	margin-right: -100%;
	width: 100%;
	backface-visibility: hidden;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
	display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
	transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
	transform: translateX(-100%);
}

/* rtl:end:ignore */

//
// Alternate transitions
//

.carousel-fade {

	.carousel-item {
		opacity: 0;
		transition-property: opacity;
		transform: none;
	}

	.carousel-item.active,
	.carousel-item-next.carousel-item-start,
	.carousel-item-prev.carousel-item-end {
		z-index: 1;
		opacity: 1;
	}

	.active.carousel-item-start,
	.active.carousel-item-end {
		@include transition(opacity 0s $carousel-transition-duration);
		z-index: 0;
		opacity: 0;
	}
}

//
// Left/right controls for nav
//

.carousel-control-prev,
.carousel-control-next {
	@include transition($carousel-control-transition);
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;
	// Use flex for alignment (1-3)
	// display: flex; // 1. allow flex styles
	display: none; // hide arrows
	padding: 0;
	width: $carousel-control-width;
	align-items: center; // 2. vertically center contents
	justify-content: center; // 3. horizontally center contents
	text-align: center;
	color: $carousel-control-color;
	opacity: $carousel-control-opacity;
	border: 0;
	background: none;

	// Hover/focus state
	&:hover,
	&:focus {
		text-decoration: none;
		color: $carousel-control-color;
		opacity: $carousel-control-hover-opacity;
		outline: 0;
	}
}

.carousel-control-prev {
	left: 0;
	background-image: if($enable-gradients, linear-gradient(90deg, color-mix(in srgb, $black 25%, transparent), color-mix(in srgb, $black 0.01%, transparent)), null);
}

.carousel-control-next {
	right: 0;
	background-image: if($enable-gradients, linear-gradient(270deg, color-mix(in srgb, $black 25%, transparent), color-mix(in srgb, $black 0.01%, transparent)), null);
}

// Icons for within
.carousel-control-prev-icon,
.carousel-control-next-icon {
	display: inline-block;
	width: $carousel-control-icon-width;
	height: $carousel-control-icon-width;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
	background-image: escape-svg($carousel-control-prev-icon-bg);
}

.carousel-control-next-icon {
	background-image: escape-svg($carousel-control-next-icon-bg);
}

// Optional indicator pips/controls
//
// Add a container (such as a list) with the following class and add an item (ideally a focusable control,
// like a button) with data-bs-target for each slide your carousel holds.

.carousel-indicators {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	display: flex;
	// Use the .carousel-control's width as margin so we don't overlay those
	margin-right: $carousel-control-width;
	margin-bottom: 1rem;
	margin-left: $carousel-control-width;
	padding: 0;
	justify-content: center;
	list-style: none;

	[data-bs-target] {
		@include transition($carousel-indicator-transition);
		margin-right: $carousel-indicator-spacer;
		margin-left: $carousel-indicator-spacer;
		padding: 0;
		width: $carousel-indicator-width;
		height: $carousel-indicator-height;
		flex: 0 1 auto;
		box-sizing: content-box;
		text-indent: -999px;
		cursor: pointer;
		opacity: $carousel-indicator-opacity;
		border: 0;
		// Use transparent borders to increase the hit area by 10px on top and bottom.
		border-top: $carousel-indicator-hit-area-height solid transparent;
		border-bottom: $carousel-indicator-hit-area-height solid transparent;
		background-color: $carousel-indicator-active-bg;
		background-clip: padding-box;
	}

	.active {
		opacity: $carousel-indicator-active-opacity;
	}
}

// Optional captions
//
//

// .carousel-caption {
// 	position: absolute;
// 	right: (100% - $carousel-caption-width) * 0.5;
// 	bottom: $carousel-caption-spacer;
// 	left: (100% - $carousel-caption-width) * 0.5;
// 	padding-top: $carousel-caption-padding-y;
// 	padding-bottom: $carousel-caption-padding-y;
// 	text-align: center;
// 	color: $carousel-caption-color;
// }

// // Dark mode carousel

// .carousel-dark {

// 	.carousel-control-prev-icon,
// 	.carousel-control-next-icon {
// 		filter: $carousel-dark-control-icon-filter;
// 	}

// 	.carousel-indicators [data-bs-target] {
// 		background-color: $carousel-dark-indicator-active-bg;
// 	}

// 	.carousel-caption {
// 		color: $carousel-dark-caption-color;
// 	}
// }
