p {

	&.p-large {
		font-size: $fs-paragraph-l; // font-size: 16px;
		line-height: 1.375; // line-height: 22px;
	}

	&.p-default {
		font-size: $fs-paragraph; // font-size: 14px;
		line-height: 1.429; // line-height: 20px;

		&--disabled {
			color: $gray-dark;
		}
	}
}

.italic {
	font-synthesis: none;
	font-style: italic;
}

.baseline {
	font-size: $fs-baseline; // font-size: 14px;
	font-weight: $fw-regular;
	line-height: 1.286; // line-height: 18px;
}

.display {
	font-size: $fs-display; // font-size: 52px
	font-weight: $fw-regular; // font-weight: 400px;
	line-height: 1.231; // line-height: 64px;
}

.overline {
	font-size: $fs-overline; // ~12px
	font-weight: $fw-semi-bold; // font-weight: 600px;
	line-height: 1.333; // line-height: 16px;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: $gray-dark;
}

.label {
	font-size: $fs-label; // ~14px;
	font-weight: $fw-medium; // font-weight: 500px;
	line-height: 1.143; // line-height: 16px;
}

.tag {
	font-size: $fs-tag; // ~12px
	font-weight: $fw-medium; // font-weight: 500px;
	line-height: 1.333; // line-height: 16px;
}

.caption {
	font-size: $fs-tag; // ~12px
	font-weight: $fw-regular; // font-weight: 400px;
	line-height: 1.333; // line-height: 16px;
}

.message {
	font-size: $fs-message; // ~11px
	font-weight: $fw-regular; // font-weight: 400px;
	line-height: 1.273; // line-height: 14px;
}

.signature {
	font-family: $font-family-signature;
	font-size: $fs-signature; // ~64px
	font-weight: $fw-regular; // font-weight: 400px;
}

/**
 *  Each function which prints values for class .h-xxl-.h-xs
 *  valuses can be edited in file variables/_var-typography.scss
 */
@include angie-headings();

/**
 *  Each function which prints all variants of font weights
 *  values can be edited in file variables/_var-typography.scss
 */
@include angie-txt-variants();
