
$sizes: (
	'0': $spacing-00,
	'4': $spacing-04,
	'8': $spacing-08,
	'12': $spacing-12,
	'16': $spacing-16,
	'24': $spacing-24,
	'32': $spacing-32,
	'40': $spacing-40,
	'48': $spacing-48,
	'64': $spacing-64,
	'96': $spacing-96,
	'auto': auto
);

@each $s, $size in $sizes {
	.p-#{$s} {
		padding: $size;
	}
	.px-#{$s} {
		padding-right: $size;
		padding-left: $size;
	}
	.py-#{$s} {
		padding-top: $size;
		padding-bottom: $size;
	}
	.pt-#{$s} {
		padding-top: $size;
	}
	.pr-#{$s} {
		padding-right: $size;
	}
	.pb-#{$s} {
		padding-bottom: $size;
	}
	.pl-#{$s} {
		padding-left: $size;
	}
	.m-#{$s} {
		margin: $size;
	}
	.mx-#{$s} {
		margin-right: $size;
		margin-left: $size;
	}
	.my-#{$s} {
		margin-top: $size;
		margin-bottom: $size;
	}
	.mt-#{$s} {
		margin-top: $size;
	}
	.mr-#{$s} {
		margin-right: $size;
	}
	.mb-#{$s} {
		margin-bottom: $size;
	}
	.ml-#{$s} {
		margin-left: $size;
	}
	@media (max-width: 767px) {
		.p-xs-#{$s} {
			padding: $size;
		}
		.px-xs-#{$s} {
			padding-right: $size;
			padding-left: $size;
		}
		.py-xs-#{$s} {
			padding-top: $size;
			padding-bottom: $size;
		}
		.pt-xs-#{$s} {
			padding-top: $size;
		}
		.pr-xs-#{$s} {
			padding-right: $size;
		}
		.pb-xs-#{$s} {
			padding-bottom: $size;
		}
		.pl-xs-#{$s} {
			padding-left: $size;
		}
		.m-xs-#{$s} {
			margin: $size !important;
		}
		.mx-xs-#{$s} {
			margin-right: $size !important;
			margin-left: $size !important;
		}
		.my-xs-#{$s} {
			margin-top: $size !important;
			margin-bottom: $size !important;
		}
		.mt-xs-#{$s} {
			margin-top: $size !important;
		}
		.mr-xs-#{$s} {
			margin-right: $size !important;
		}
		.mb-xs-#{$s} {
			margin-bottom: $size !important;
		}
		.ml-xs-#{$s} {
			margin-left: $size !important;
		}
	}
	@media (max-width: 991px) {
		.p-sm-#{$s} {
			padding: $size;
		}
		.px-sm-#{$s} {
			padding-right: $size;
			padding-left: $size;
		}
		.py-sm-#{$s} {
			padding-top: $size;
			padding-bottom: $size;
		}
		.pt-sm-#{$s} {
			padding-top: $size;
		}
		.pr-sm-#{$s} {
			padding-right: $size;
		}
		.pb-sm-#{$s} {
			padding-bottom: $size;
		}
		.pl-sm-#{$s} {
			padding-left: $size;
		}
		.m-sm-#{$s} {
			margin: $size;
		}
		.mx-sm-#{$s} {
			margin-right: $size;
			margin-left: $size;
		}
		.my-sm-#{$s} {
			margin-top: $size;
			margin-bottom: $size;
		}
		.mt-sm-#{$s} {
			margin-top: $size;
		}
		.mr-sm-#{$s} {
			margin-right: $size;
		}
		.mb-sm-#{$s} {
			margin-bottom: $size;
		}
		.ml-sm-#{$s} {
			margin-left: $size;
		}
	}
}
