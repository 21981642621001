/** Lu Row columns
 * Use the responsive .lu-row-cols-* classes quickly to create basic grid layouts or to control your card layouts.
 * IMPORTANT: only place where we can put this class is next to the lu-row
 * <div class="lu-row lu-row-cols-*">
*/

/** Use sass:math for math.div*/
@use "sass:math";

@mixin calc-row-cols($index, $class) {
	@if ($class == "") and ($index > 0) {
		.lu-row-cols-#{$index} {

			& > * {
				width: math.div(100%, $index);
				flex: 0 0 auto;
			}
		}
	}
	@else if ($class) and ($index > 0) {
		.lu-row-cols-#{$class}-#{$index} {

			& > * {
				width: math.div(100%, $index);
				flex: 0 0 auto;
			}
		}
	}
}

// Loop around the number of $columns(12)
@mixin loop-grid-rows($columns, $class) {
	@for $i from 0 through $columns {
		@include calc-row-cols($i, $class);
	}
}

// Create lu-row-cols=* class
@mixin make-row-cols($class:"") {
	@include loop-grid-rows($grid-columns, $class);
}

@include make-row-cols();

// Small grid (xs)
@media (min-width: $lu-screen-xs-min) {
	@include make-row-cols(xs);
}

// Small grid (sm)
@media (min-width: $lu-screen-s-min) {
	@include make-row-cols(sm);
}


// Medium grid (md)
@media (min-width: $lu-screen-m-min) {
	@include make-row-cols(md);
}

// Large grid (lg)
@media (min-width: $lu-screen-l-min) {
	@include make-row-cols(lg);
}

// Extra large (xl) We don't use extra large at the moment
/* @media (min-width: $lu-screen-xl-min) {
	@include make-row-cols(xl);
} */