/* stylelint-disable color-no-hex */

//##  TYPOGRAPHY
//
// Font, line-height, and color for body text, headings, and more.

// Base coefficient used to utilize font-size: 62.5% placed on HTML from _normalize.scss
$fs-base-coefficient: 1.6 !default;

//Headings
$fs-heading-xxl: $fs-base-coefficient * 2rem !default; // ~32px
$fs-heading-xl: $fs-base-coefficient * 1.56rem !default; // ~25px
$fs-heading-l: $fs-base-coefficient * 1.25rem !default; // ~20px
$fs-heading-m: $fs-base-coefficient * 1.13rem !default; // ~18px
$fs-heading-s: $fs-base-coefficient * 1rem !default; // ~16px
$fs-heading-xs: $fs-base-coefficient * 0.875rem !default; // ~14px

//Paragraphs
$fs-paragraph-l: $fs-base-coefficient * 1rem !default; // ~16px
$fs-paragraph: $fs-base-coefficient * 0.875rem !default; // ~14px

//Buttons
$fs-button-l: $fs-base-coefficient * 1rem !default; // ~16px
$fs-button-s: $fs-base-coefficient * 0.875rem !default; // ~14px

//Other
$fs-baseline: $fs-base-coefficient * 0.875rem !default; // ~14px
$fs-display: $fs-base-coefficient * 3.25rem !default; // ~52px
$fs-overline: $fs-base-coefficient * 0.75rem !default; // ~12px
$fs-label: $fs-base-coefficient * 0.875rem !default; // ~14px
$fs-tag: $fs-base-coefficient * 0.75rem !default; // ~12px
$fs-message: $fs-base-coefficient * 0.69rem !default; // ~11px
$fs-signature: $fs-base-coefficient * 4rem !default; // ~64px

// font-weight
$fw-regular: 400 !default;
$fw-medium: 500 !default;
$fw-semi-bold: 600 !default;

// PILLS colors
// These are currently temporary placeholders. Final colors name/value to be defined by design team
// See client/common/styles/_common-vars.scss
// See client/common/styles/_design-tokens.scss
